import "../firebase"
import * as Storage from 'firebase/storage';
import {User as FirebaseUser} from "@firebase/auth";
import {UploadURLResponse} from "../../functions/src/shared/response";
import {FirebaseAuthAccess} from "./firebase-auth-access";
import {publicConfig} from "../../functions/src/shared/public-config";

const storage = Storage.getStorage();
if (publicConfig.env === "local") {
  Storage.connectStorageEmulator(storage, "127.0.0.1", 9199);
}

export class FirebaseStorageAccess {

  constructor(
    private readonly auth: FirebaseAuthAccess,
  ) {
  }

  async uploadUserIcon(user: FirebaseUser, blob: Blob): Promise<UploadURLResponse> {
    const response = await this.uploadFile(`/users/${user.uid}`, blob);
    await this.auth.updatePhotoURL(user, response.url);
    return response;
  }

  private async uploadFile(path: string, blob: Blob): Promise<UploadURLResponse> {
    const x = await Storage.uploadBytes(Storage.ref(storage, path), blob, {contentType: blob.type});
    const url = await Storage.getDownloadURL(x.ref);
    return ({url});
  }

}
