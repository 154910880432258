import React, {useEffect, useState} from "react";
import {Spinner} from "../../components/Spinner";
import {useUser} from "../../access/firestore-access";
import {Paths} from "../../lib/paths";
import {core} from "../../core";
import {useAdminAccessContext} from "../../components/guards/AdminGuard";
import {User as FirebaseUser} from "@firebase/auth";
import {EmailVerifiedIcon} from "../../components/EmailVerifiedIcon";
import {Providers} from "../../access/firebase-auth-access";
import {Loading} from "../../../functions/src/shared/types/loading";

export const AdminUsersDetail = () => {

  const context = useAdminAccessContext()
  const targetUserId = Paths.admin.users.user.usePath().userId;
  const user = useUser(targetUserId);
  const [firebaseUser, setFirebaseUser] = useState<Loading<FirebaseUser>>("loading")

  useEffect(() => {
    const subscription = core.api.getFirebaseUser(context.user.value.firebase, targetUserId)
      .subscribe(response => {
        setFirebaseUser(response.value)
      });
    return () => subscription.unsubscribe();
  }, [context.user.value, targetUserId])

  if (user === "loading" || firebaseUser === "loading") {
    return <div className="row">
      <div className="col-12 offset-xl-2 col-xl-8 text-center">
        <h1 className="page-title">User</h1>
      </div>

      <div className="col-12 offset-xl-2 col-xl-8 text-center pt-5">
        <Spinner magnification={3}/>
      </div>
    </div>
  }

  if (user === null) {
    return <div className="row">
      <div className="col-12 offset-xl-2 col-xl-8 text-center">
        <h1 className="page-title">User</h1>
      </div>

      <div className="col-12 offset-xl-2 col-xl-8 text-center pt-5">
        ユーザーが見つかりませんでした。
      </div>
    </div>
  }

  const providers = new Providers(firebaseUser.providerData);

  return <>
    <div className="row">
      <div className="col-12 offset-xl-2 col-xl-8 text-center">
        <h1 className="page-title">User</h1>
      </div>

      <div className="col-12 offset-xl-2 col-xl-8 text-center d-flex flex-column gap-3 pt-5">

        <h3>基本情報</h3>

        <table className="table table-striped">
          <tbody>
          <tr>
            <th>ID</th>
            <td>{user.id}</td>
          </tr>
          <tr>
            <th>Name</th>
            <td>{user.nickname}</td>
          </tr>
          <tr>
            <th>Registered at</th>
            <td>{new Date(user.createdAt).toLocaleString()}</td>
          </tr>
          <tr>
            <th>Updated at</th>
            <td>{new Date(user.updatedAt).toLocaleString()}</td>
          </tr>
          <tr>
            <th>Last SignIn at</th>
            <td>{firebaseUser.metadata.lastSignInTime}</td>
          </tr>
          <tr>
            <th>Email</th>
            <td>
              <EmailVerifiedIcon emailVerified={firebaseUser.emailVerified}/>
              {firebaseUser.email}
            </td>
          </tr>
          <tr>
            <th>Stripe Customer ID</th>
            <td>{user.stripeCustomer ?? "未設定"}</td>
          </tr>
          </tbody>
        </table>

        <hr/>

        <h3>ログイン</h3>

        <table className="table table-striped">
          <tbody>
          <tr>
            <th colSpan={2} className="bg-dark text-light">Google</th>
          </tr>
          <tr>
            <th>連携状態</th>
            <td>{providers.google === undefined ? "未連携" : "連携済み"}</td>
          </tr>
          {providers.google !== undefined && (
            <>
              <tr>
                <th>ID</th>
                <td>{providers.google.uid}</td>
              </tr>
              <tr>
                <th>displayName</th>
                <td>{providers.google.displayName}</td>
              </tr>
              <tr>
                <th>Email</th>
                <td>{providers.google.email}</td>
              </tr>
            </>
          )}
          <tr>
            <th colSpan={2} className="bg-dark text-light">パスワード</th>
          </tr>
          <tr>
            <th>登録状態</th>
            <td>{providers.password === undefined ? "未登録" : "登録済み"}</td>
          </tr>
          {providers.password !== undefined && (
            <>
              <tr>
                <th>Email</th>
                <td>{providers.password.email}</td>
              </tr>
            </>
          )}
          </tbody>
        </table>
      </div>
    </div>
  </>
}
