import React from "react";
import {Time} from "../../functions/src/shared/lib/time";

export const TimeRange = (props: { base: string, minutes: number }) => {
  const time = Time.parse(props.base);
  if (time === undefined) {
    console.warn(`invalid time value: ${props.base}`);
    return <></>;
  }

  return <>{time.toString()} - {time.addMinutes(props.minutes).toString()} </>
}
