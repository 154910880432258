import {useForm} from "react-hook-form";
import {core} from "../core";
import React, {useState} from "react";
import {Spinner} from "./Spinner";
import {CommonAccessValue} from "./guards/CommonAccessGuard";
import {Responses} from "../../functions/src/shared/response";
import {UserInfo} from "firebase/auth";
import {Navigate} from "react-router-dom";
import {Paths} from "../lib/paths";

type PasswordForm = {
  password: string,
}

export const LoginPasswordUpdate = (props: { user: CommonAccessValue, previous: UserInfo | undefined }) => {

  const form = useForm<PasswordForm>({mode: "onBlur"})
  const [status, setStatus] = useState<"FORM" | "COMPLETED" | "DONE" | "EXPIRED">("FORM");

  if (status === "DONE") {
    return <Navigate to={Paths.my.path}/>
  }
  if (status === "EXPIRED") {
    return <Navigate to={Paths.login.pathWithFrom(Paths.account.update.password.path)}/>
  }

  const onSubmit = (value: PasswordForm) => {
    return core.auth.updatePassword(props.user.firebase, value.password)
      .then(() => {
        setStatus("COMPLETED");
        setTimeout(() => {
          setStatus("DONE");
        }, 2500);
      })
      .catch(e => {
        if (e.code === "auth/weak-password") {
          form.setError("password", {message: "パスワードが脆弱すぎます。より強固なパスワードを指定して下さい。"});
          return;
        } else if (e.code === "auth/requires-recent-login") {
          form.setError("root", {message: "ログインの有効期限が切れました。ログインし直してからもう一度お試し下さい。"});
          return;
        } else {
          console.error(e);
          form.setError("root", {message: Responses.messages.systemError});
          return;
        }
      });
  }

  const actionLabel = props.previous !== undefined ? "更新" : "設定"

  return <>
    <form className="d-flex flex-column gap-3" onSubmit={form.handleSubmit(onSubmit)}>
      <div>
        <div className="form-floating">
          <input
            id="password"
            className="form-control"
            disabled={form.formState.isSubmitting}
            {...form.register("password", {required: Responses.messages.required})}
          />
          <label htmlFor="password">パスワード</label>
        </div>

        {form.formState.errors.password?.message &&
          <div className="text-danger">{form.formState.errors.password.message}</div>
        }
      </div>

      <button className="form-control btn btn-primary">
        {form.formState.isSubmitting
          ? <Spinner magnification={1}/>
          : <>{actionLabel}</>
        }
      </button>

      {form.formState.errors.root?.message &&
        <div className="text-danger">{form.formState.errors.root.message}</div>
      }
      {status === "COMPLETED" && (
        <div className="text-success">{actionLabel}に成功しました。 </div>
      )}
    </form>
  </>
}
