import React, {createRef, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {core} from "../core";
import Cropper, {ReactCropperElement} from "react-cropper";
import {User as FirebaseUser} from "@firebase/auth";
import {Responses} from "../../functions/src/shared/response";
import {Spinner} from "./Spinner";
import {LoadingOption} from "../../functions/src/shared/types/loading";

export const UserProfileIconUpload = (props: {
  firebaseUser: FirebaseUser,
}) => {
  const photoURL = props.firebaseUser.photoURL

  const form = useForm();
  const [image, setImage] = useState<LoadingOption<string>>("loading");
  const cropperRef = createRef<ReactCropperElement>();

  useEffect(() => {
    if (photoURL === null) {
      setImage(null);
      return;
    }
    // 画像の読み込みが完了したら、ローディングステートを更新
    const img = new Image();
    img.src = photoURL; // 画像のURLを設定
    img.onload = () => {
      setImage(photoURL);
    };
  }, [photoURL]);

  const onChange = (e: any) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as any);
    };
    reader.readAsDataURL(files[0]);
  };

  const canvasToBlob = (canvas: HTMLCanvasElement): Promise<Blob | null> => {
    return new Promise<Blob | null>((resolve) => canvas.toBlob((blob) => resolve(blob)))
  }

  async function resizeCanvasToBlob(canvas: HTMLCanvasElement, targetSizeInBytes: number, factor: number = 1): Promise<Blob | null> {
    const blob = await canvasToBlob(canvas)
    if (blob === null) {
      return null
    }

    if (blob.size <= targetSizeInBytes) {
      return blob;
    }

    const size = canvas.width * factor;

    const newCanvas = document.createElement('canvas');
    newCanvas.width = size;
    newCanvas.height = size;

    const ctx = newCanvas.getContext('2d');
    ctx!.drawImage(canvas, 0, 0, size, size);
    return resizeCanvasToBlob(newCanvas, targetSizeInBytes, 0.9);
  }

  const onSubmit = () => {
    const cropper = cropperRef.current?.cropper
    if (cropper === undefined) {
      form.setError("root", {message: "画像が選択されていません。"})
      return;
    }

    const canvas = cropper.getCroppedCanvas();

    return resizeCanvasToBlob(canvas, 1024 * 1024 * 2).then(blob => {
      if (blob === null) {
        form.setError("root", {message: "画像の処理に失敗しました。別の画像を指定してください。"})
        return;
      }
      return core.storage.uploadUserIcon(props.firebaseUser, blob).then(x => setImage(x.url));
    }).catch(e => {
      console.error(e);
      form.setError("root", {message: Responses.messages.systemError})
    });
  };

  return (
    <div className="d-flex flex-column gap-3 align-items-center">
      {image === "loading" && (
        <Spinner magnification={3} className="mt-3"/>
      )}
      {image !== null && (
        <Cropper
          ref={cropperRef}
          src={image}
          style={{maxHeight: "300px"}}
          initialAspectRatio={1}
          aspectRatio={1}
          viewMode={1}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          autoCropArea={1}
          checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
          guides={true}
        />
      )}

      <form onSubmit={form.handleSubmit(onSubmit)} className="d-flex flex-column gap-3 align-items-center">
        <input type="file" className="form-control" onChange={onChange} disabled={form.formState.isSubmitting}/>
        <button className="btn btn-primary" disabled={form.formState.isSubmitting}>
          アップロード
        </button>

        {form.formState.errors.root?.message &&
          <div className="text-danger">{form.formState.errors.root.message}</div>
        }
      </form>
    </div>
  )
}
