export interface Response<T> {
  status: number,
  value: T
}

export interface SuccessOKResponse<T> extends Response<T> {
  status: 200,
  value: T
}

export type ErrorResponseValue = {
  kind: string,
  message: string,
}

export interface ErrorResponse extends Response<ErrorResponseValue> {
  status: number,
  value: ErrorResponseValue
}

export type BadRequestFieldKind = "TYPE_NOT_MATCH"
  | "UNKNOWN"
  | "MISSING"
  | "TOO_SHORT"
  | "TOO_LONG"
  | "INVALID_CHARACTER"
  | "BAD_FORMAT"

export type BadRequestField<T> = [T, BadRequestFieldKind][]

export interface BadRequestErrorResponse<T> extends ErrorResponse {
  status: 400,
  value: {
    kind: "BAD_REQUEST",
    errors: BadRequestField<T>
    message: string,
  }
}

export interface UnauthorizedErrorResponse extends ErrorResponse {
  status: 401,
  value: {
    kind: "UNAUTHORIZED",
    message: string,
  }
}

export interface ForbiddenErrorResponse extends ErrorResponse {
  status: 403,
  value: {
    kind: "FORBIDDEN",
    message: string,
  }
}

export interface NotFoundErrorResponse extends ErrorResponse {
  status: 404,
  value: {
    kind: "NOT_FOUND",
    message: string,
  }
}

export interface MethodNotAllowedErrorResponse extends ErrorResponse {
  status: 405,
  value: {
    kind: "METHOD_NOT_ALLOWED",
    message: string,
  }
}

export interface ConflictErrorResponse extends ErrorResponse {
  status: 409,
  value: {
    kind: "CONFLICT",
    message: string,
  }
}

export class Responses {

  static successOK<T>(value: T): SuccessOKResponse<T> {
    return {status: 200, value}
  }

  static errorBadRequest<T>(
    errors: BadRequestField<T>,
    message: string = "リクエストパラメータが正しくありません。"
  ): BadRequestErrorResponse<T> {
    return {status: 400, value: {kind: "BAD_REQUEST", errors, message}}
  }

  static errorUnauthorized(message: string): UnauthorizedErrorResponse {
    return {status: 401, value: {kind: "UNAUTHORIZED", message}}
  }

  static errorForbidden(message: string): ForbiddenErrorResponse {
    return {status: 403, value: {kind: "FORBIDDEN", message}}
  }

  static errorNotFound(message: string): NotFoundErrorResponse {
    return {status: 404, value: {kind: "NOT_FOUND", message}}
  }

  static errorMethodNotAllowed(message: string): MethodNotAllowedErrorResponse {
    return {status: 405, value: {kind: "METHOD_NOT_ALLOWED", message}}
  }

  static errorConflict(message: string): ConflictErrorResponse {
    return {status: 409, value: {kind: "CONFLICT", message}}
  }

  static readonly messages = {
    convert: (errors: BadRequestFieldKind[]): string => {
      const messages = [];
      if (errors.indexOf("TYPE_NOT_MATCH") !== -1) {
        messages.push("リクエストの型が一致しません。");
      }
      if (errors.indexOf("UNKNOWN") !== -1) {
        messages.push("未知の値が指定されました。");
      }
      if (errors.indexOf("MISSING") !== -1) {
        messages.push("値が指定されていません。");
      }
      if (errors.indexOf("TOO_SHORT") !== -1) {
        messages.push("入力された値が短すぎます。");
      }
      if (errors.indexOf("TOO_LONG") !== -1) {
        messages.push("入力された値が長すぎます。");
      }
      if (errors.indexOf("INVALID_CHARACTER") !== -1) {
        messages.push("不正な文字が含まれています。");
      }
      return messages.join("また、");
    },
    required: {
      value: true,
      message: `必須項目が入力されていません。`,
    },
    requiredNotSelected: {
      value: true,
      message: `必須項目が選択されていません。`,
    },
    requiredNotEmpty: {
      value: true,
      message: `空の値が指定されています。`,
    },
    minLength: (len: number) => ({
      value: len,
      message: `${len}文字以上の文字列を入力して下さい。`
    }),
    maxLength: (len: number) => ({
      value: len,
      message: `${len}文字以内の文字列を入力して下さい。`
    }),
    katakana: (value: string) => {
      value = (value == null) ? "" : value;
      if (value.match(/^[ァ-ヶー　]+$/)) {
        return true;
      } else {
        return "全角カタカナのみを入力して下さい。";
      }
    },
    ascii: (value: string) => {
      for (let i = 0; i < value.length; i++) {
        if (127 < value.charCodeAt(i)) {
          console.log(value.charCodeAt(i))
          return "半角英数記号および半角スペースのみを入力して下さい。";
        }
      }
      return true;
    },
    systemError: "システムエラーが発生しました。この問題が繰り返し発生する場合は、大変お手数ですが、お問い合わせフォームからお問い合わせ下さい。",
  }
}

export type UploadURLResponse = {
  url: string;
};
