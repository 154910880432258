import * as Rx from "rxjs";
import {FirebaseAuthAccess} from "./firebase-auth-access";
import {ApiClient} from "../lib/api-client";
import {User as FirebaseUser} from "@firebase/auth";
import {AdminId, ReservationId, UserId} from "../../functions/src/shared/entity/identity";
import {UpdateUserRequest, UpdateUserResponse} from "../../functions/src/shared/entity/user";
import {UpdateAdminRequest, UpdateAdminResponse} from "../../functions/src/shared/entity/admin";
import {SuccessOKResponse} from "../../functions/src/shared/response";
import {publicConfig} from "../../functions/src/shared/public-config";
import {UpdateStaffRequest, UpdateStaffResponse} from "../../functions/src/shared/entity/staff";
import {CreateReservationRequest, CancelReservationRequest, CreateReservationResponse, CancelReservationResponse, ResumePaymentResponse} from "../../functions/src/shared/entity/reservation";
import { PaymentMethodDelete, PaymentMethodEditResponse, PaymentMethodInsert, PaymentMethodListResponse } from "../../functions/src/shared/entity/stripe";

function header(idToken: string | null, append: { [_: string]: string } = {}): { [_: string]: string } {
  if (idToken === null) {
    return {...append, "X-Requested-With": publicConfig.serviceName};
  }
  return {...append, "X-ID-Token": idToken, "X-Requested-With": publicConfig.serviceName};
}

export class ServiceApiAccess {

  private static SERVICE_API_BASE = "/api/v1/";

  constructor(
    private readonly auth: FirebaseAuthAccess,
  ) {
  }

  updateUser(user: FirebaseUser, request: UpdateUserRequest): Rx.Observable<UpdateUserResponse> {
    return Rx.from(this.auth.getIdToken(user)).pipe(
      Rx.switchMap(token =>
        ApiClient.update<UpdateUserResponse>(
          "PUT",
          ServiceApiAccess.SERVICE_API_BASE + `updateUser`,
          header(token, ApiClient.CONTENT_TYPES.APPLICATION_JSON),
          request
        ),
      )
    );
  }

  addAdmin(user: FirebaseUser, adminId: AdminId): Rx.Observable<UpdateAdminResponse> {
    return this.updateAdmin(user, {operation: "ADD", adminId})
  }

  removeAdmin(user: FirebaseUser, adminId: AdminId): Rx.Observable<UpdateAdminResponse> {
    return this.updateAdmin(user, {operation: "REMOVE", adminId})
  }

  private updateAdmin(user: FirebaseUser, request: UpdateAdminRequest): Rx.Observable<UpdateAdminResponse> {
    return Rx.from(this.auth.getIdToken(user)).pipe(
      Rx.switchMap(token =>
        ApiClient.update<UpdateAdminResponse>(
          "PUT",
          ServiceApiAccess.SERVICE_API_BASE + `updateAdmin`,
          header(token, ApiClient.CONTENT_TYPES.APPLICATION_JSON),
          request,
        ),
      ),
    );
  }

  updateStaff(user: FirebaseUser, request: UpdateStaffRequest): Rx.Observable<UpdateStaffResponse> {
    return Rx.from(this.auth.getIdToken(user)).pipe(
      Rx.switchMap(token =>
        ApiClient.update<UpdateStaffResponse>(
          "PUT",
          ServiceApiAccess.SERVICE_API_BASE + `updateStaff`,
          header(token, ApiClient.CONTENT_TYPES.APPLICATION_JSON),
          request,
        ),
      ),
    );
  }

  createReservation(user: FirebaseUser, request: CreateReservationRequest): Rx.Observable<CreateReservationResponse> {
    return Rx.from(this.auth.getIdToken(user)).pipe(
      Rx.switchMap(token =>
        ApiClient.update<CreateReservationResponse>(
          "POST",
          ServiceApiAccess.SERVICE_API_BASE + `createReservation`,
          header(token, ApiClient.CONTENT_TYPES.APPLICATION_JSON),
          request,
        ),
      ),
    );
  }

  cancelReservation(user: FirebaseUser, request: CancelReservationRequest): Rx.Observable<CancelReservationResponse> {
    return Rx.from(this.auth.getIdToken(user)).pipe(
      Rx.switchMap(token =>
        ApiClient.update<CancelReservationResponse>(
          "POST",
          ServiceApiAccess.SERVICE_API_BASE + `cancelReservation`,
          header(token, ApiClient.CONTENT_TYPES.APPLICATION_JSON),
          request,
        ),
      ),
    );
  }

  resumePayment(user: FirebaseUser, reservationId: ReservationId): Rx.Observable<ResumePaymentResponse> {
    return Rx.from(this.auth.getIdToken(user)).pipe(
      Rx.switchMap(token =>
        ApiClient.update<ResumePaymentResponse>(
          "POST",
          ServiceApiAccess.SERVICE_API_BASE + `resumePayment`,
          header(token, ApiClient.CONTENT_TYPES.APPLICATION_JSON),
          { reservationId },
        ),
      ),
    );
  }

  getFirebaseUser(user: FirebaseUser, userId: UserId): Rx.Observable<SuccessOKResponse<FirebaseUser>> {
    return Rx.from(this.auth.getIdToken(user)).pipe(
      Rx.switchMap(token =>
        ApiClient.fetch<SuccessOKResponse<FirebaseUser>>(
          ServiceApiAccess.SERVICE_API_BASE + `getFirebaseUser?userId=${userId}`,
          header(token, ApiClient.CONTENT_TYPES.APPLICATION_JSON),
        ),
      ),
    );
  }

  listPaymentMethods(user: FirebaseUser): Rx.Observable<PaymentMethodListResponse> {
    return Rx.from(this.auth.getIdToken(user)).pipe(
      Rx.switchMap(token =>
        ApiClient.fetch<PaymentMethodListResponse>(
          ServiceApiAccess.SERVICE_API_BASE + `listPaymentMethods`,
          header(token)
        ),
      ),
    );
  }

  appendPaymentMethod(user: FirebaseUser, request: PaymentMethodInsert): Rx.Observable<PaymentMethodEditResponse> {
    return Rx.from(this.auth.getIdToken(user)).pipe(
      Rx.switchMap(token =>
        ApiClient.update<PaymentMethodEditResponse>(
          "POST",
          ServiceApiAccess.SERVICE_API_BASE + `appendPaymentMethod`,
          header(token, ApiClient.CONTENT_TYPES.APPLICATION_JSON),
          request,
        ),
      ),
    );
  }

  deletePaymentMethod(user: FirebaseUser, request: PaymentMethodDelete): Rx.Observable<PaymentMethodEditResponse> {
    return Rx.from(this.auth.getIdToken(user)).pipe(
      Rx.switchMap(token =>
        ApiClient.update<PaymentMethodEditResponse>(
          "POST",
          ServiceApiAccess.SERVICE_API_BASE + `deletePaymentMethod`,
          header(token, ApiClient.CONTENT_TYPES.APPLICATION_JSON),
          request,
        ),
      ),
    );
  }
}


