import React from "react";
import * as Rx from "rxjs";
import {Query} from "../../functions/src/shared/lib/query";
import {UserId} from "../../functions/src/shared/entity/identity";
import {Spinner} from "../components/Spinner";
import {core} from "../core";
import {EmailAndPasswordForm, FirebaseAuthProvider} from "../access/firebase-auth-access";
import {useForm} from "react-hook-form";
import {Navigate, NavLink} from "react-router-dom";
import {Paths} from "../lib/paths";
import {UserProfileForm} from "../components/form/UserProfileForm";
import {User} from "@firebase/auth";
import {useCommonAccessContext} from "../components/guards/CommonAccessGuard";
import {FirestoreAccess} from "../access/firestore-access";
import {Responses} from "../../functions/src/shared/response";

export const AccountRegister = () => {
  const query = new Query(window.location.search.slice(1));

  const context = useCommonAccessContext();
  const [status, setStatus] = React.useState<
    "REGISTER_FORM" | "SUCCESS" | "PROCESSING" | "ALREADY_REGISTERED" | "BANNED"
    | { kind: "PROFILE", value: User }
  >("PROCESSING");
  const form = useForm<EmailAndPasswordForm>({mode: "onBlur"})

  const onClickSignUpButton = (provider: FirebaseAuthProvider) => {
    setStatus("PROCESSING");
    return core.auth.signInWithRedirect(provider);
  };

  const onSubmitEmailAndPasswordForm = (value: EmailAndPasswordForm) =>
    core.auth.createUserWithEmailAndPassword(value).then(cred => {
      if (cred.user !== null) {
        context.user.set({firebase: cred.user, service: null});
        setStatus({kind: "PROFILE", value: cred.user});
        return;
      }
    }).catch(e => {
      if (e.code === "auth/weak-password") {
        form.setError("password", {message: "パスワードが脆弱すぎます。より強固なパスワードを指定して下さい。"});
        return;
      } else if (e.code === "auth/invalid-email") {
        form.setError("email", {message: "メールアドレスの形式が正しくありません。"});
        return;
      } else if (e.code === "auth/email-already-in-use") {
        form.setError("email", {message: "このメールアドレスは既に登録されています。"});
        return;
      } else if (e.code === "auth/user-disabled") {
        setStatus("BANNED");
        return;
      } else {
        console.error(e);
        form.setError("root", {message: Responses.messages.systemError});
        return;
      }
    });

  React.useEffect(() => {
    const userValue = context.user.value;
    if (userValue === "loading") {
      return;
    }
    const subscription = Rx.from(core.auth.getRedirectResult().then(async cred => {
      if (cred === null) {
        if (userValue === null) {
          setStatus("REGISTER_FORM");
        } else if (userValue.service !== null) {
          setStatus("ALREADY_REGISTERED");
        } else {
          setStatus({kind: "PROFILE", value: userValue.firebase});
        }
        return;
      }
      const user = await FirestoreAccess.getUser(UserId(cred.user.uid))
      if (user !== null) {
        // リダイレクトで戻ってきたユーザーであり、Firebase にログインしているユーザーであり、
        // 更に会員登録まで完了しているユーザーなので、ログインを促す
        setStatus("ALREADY_REGISTERED");
        return;
      }
      // Firebase ログインに成功したユーザーであり、未登録のユーザーなので、会員登録処理を促す
      setStatus({kind: "PROFILE", value: cred.user});
    }, error => {
      if (error.code === "auth/user-disabled") {
        setStatus("BANNED");
        return;
      } else {
        console.error(error);
        alert(Responses.messages.systemError);
      }
    })).subscribe();

    return () => subscription.unsubscribe();
  }, [context.user]);

  if (status === "SUCCESS") {
    return <Navigate to={Paths.from(query.getAsOpt("from"), Paths.my.path)}/>
  }

  return <>
    <div className="row">
      <div className="col-12 mt-3">
        <NavLink to={Paths.top.path}>&lt;&lt;予約ページに戻る</NavLink>
      </div>
      <div className="col-12 offset-sm-2 col-sm-8 col-lg-6 offset-lg-3 text-center pt-3">
        {(status === "PROCESSING") ? (
          <>
            <Spinner magnification={3}/>
          </>
        ) : (
          <>
            <div className="d-flex flex-column gap-3">
              <div>
                <h1 className="page-title">会員登録</h1>
              </div>

              {(typeof status !== "string" && status?.kind === "PROFILE") ? (
                <>
                  <UserProfileForm
                    user={{service: null, firebase: status.value}}
                    onSuccess={(user) => {
                      context.user.set({service: user, firebase: status.value});
                      setStatus("SUCCESS")
                    }}
                  />
                </>
              ) : (
                <>
                  <div className="text-start">
                    <p>オンラインチャットのご予約とお支払ですが会員登録とクレジットカードのご登録が必要となります。</p>
                    <p>Gmailでのご登録は以下Googleで会員登録より、それ以外のメールアドレスで登録をご希望の場合はメールアドレスで会員登録よりご登録の方をお願いいたします。</p>
                  </div>

                  <div className="d-flex flex-column gap-3">
                    <button className="w-100 btn btn-primary" onClick={() => onClickSignUpButton("Google")}>
                      <i className="me-2 fa-fw fab fa-google"></i>
                      Googleで会員登録
                    </button>

                    <hr/>

                    <form onSubmit={form.handleSubmit(onSubmitEmailAndPasswordForm)} className="d-flex flex-column gap-1">
                      <input
                        className="form-control"
                        placeholder="example@example.com"
                        type="text"
                        disabled={form.formState.isSubmitting}
                        {...form.register("email", {required: Responses.messages.required})}
                      />
                      {form.formState.errors.email?.message &&
                        <span className="text-danger">{form.formState.errors.email.message}</span>
                      }

                      <input
                        className="form-control"
                        placeholder="password"
                        type="password"
                        disabled={form.formState.isSubmitting}
                        {...form.register("password", {required: Responses.messages.required})}
                      />
                      {form.formState.errors.password?.message &&
                        <span className="text-danger">{form.formState.errors.password.message}</span>
                      }

                      <button className="w-100 btn btn-outline-dark" disabled={form.formState.isSubmitting}>
                        {form.formState.isSubmitting
                          ? <Spinner magnification={1}/>
                          : (
                            <>
                              <i className="me-2 fa-fw fas fa-envelope"></i>
                              メールアドレスで会員登録
                            </>
                          )
                        }
                      </button>

                      {form.formState.errors.root?.message &&
                        <div className="text-danger">{form.formState.errors.root.message}</div>
                      }
                    </form>
                  </div>

                  {status === "BANNED" && (
                    <span className="text-danger">
                      このアカウントは停止されました。<br/>
                      異議申し立てがある場合は、お問い合わせフォームからお問い合わせ下さい。
                    </span>
                  )}

                  {status === "ALREADY_REGISTERED" && (
                    <span className="text-danger">
                      既にアカウントが登録されています。<br/>
                      <NavLink to={Paths.login.path}>ログインページからログイン</NavLink>をお願いします。
                    </span>
                  )}

                  <div>
                    <NavLink to={Paths.login.path}>ログインはこちら</NavLink>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  </>
}
