export function nonNullable<T>(value: T): value is NonNullable<T> {
  return value !== null && value !== undefined;
}

export function toMap<K, V>(values: [K, V][]): Map<K, V[]> {
  const resultMap = new Map<K, V[]>();
  for (const [key, value] of values) {
    if (resultMap.has(key)) {
      resultMap.get(key)!.push(value);
    } else {
      resultMap.set(key, [value]);
    }
  }

  return resultMap;
}
