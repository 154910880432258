import React from "react";
import {useSignUpAccessContext} from "../components/guards/SignUpGuard";
import {useUser} from "../access/firestore-access";
import {Spinner} from "../components/Spinner";
import {Paths} from "../lib/paths";

export const User = () => {

  const context = useSignUpAccessContext();
  const path = Paths.users.user.usePath()
  const user = useUser(path.userId)

  return <>
    <div className="row">
      <div className="col-12 text-center">
        <h1 className="page-title">User</h1>
      </div>

      <div className="col-12 text-center">
        {user === "loading" ? <Spinner magnification={3}/> : user === null ? <>User not found!</> :
          <span>ID: {user.id} {user.id === context.user.value.service.id && "(You!)"}</span>
        }
      </div>
    </div>
  </>
}
