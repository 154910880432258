import React from "react";
import {StaffForm} from "../../components/form/StaffForm";
import {useAdminAccessContext} from "../../components/guards/AdminGuard";

export const AdminStaffsNew = () => {

  const context = useAdminAccessContext()

  return <>
    <div className="row">
      <div className="col-12 offset-xl-2 col-xl-8 text-center">
        <h1 className="page-title">Staff</h1>
      </div>

      <div className="col-12 offset-xl-2 col-xl-8 text-center d-flex flex-column gap-3 pt-5">
        <StaffForm firebaseUser={context.user.value.firebase} previous={null} />
      </div>
    </div>
  </>
}
