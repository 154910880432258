import React from "react";
import {useSignUpAccessContext} from "../components/guards/SignUpGuard";
import {NavLink} from "react-router-dom";
import {Paths} from "../lib/paths";
import {useReservationsByUserId} from "../access/firestore-access";
import {Spinner} from "../components/Spinner";

export const MyReservations = () => {

  const context = useSignUpAccessContext();
  const reservations = useReservationsByUserId(50, context.user.value.service.id);

  return <>
    <div className="row">
      <div className="col-12 text-center">
        <h1 className="page-title">予約一覧</h1>
      </div>
    </div>

    <div className="row mt-5">
      <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 d-flex flex-column gap-5 pt-3">
        <div className="text-center pt-3">
          <NavLink to={Paths.my.path}>マイページに戻る</NavLink>
        </div>

        <div className="d-flex flex-column gap-3 align-items-center">
          {reservations.values === "loading" ? <Spinner magnification={3}/> : reservations.values.map(reservation =>
            <div key={reservation.id} className="card w-100">
              <h5 className="card-header d-flex">
                <span className="flex-grow-1">予約</span>
              </h5>

              <div className="card-body">
                <div className="card-text">
                  <div>時間: {reservation.date} {reservation.time}</div>
                  <div>ご予約状況: {reservation.statusLabel}</div>
                  <div className="text-end">
                    <small className="text-muted">
                      キャンセルのご連絡は
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={"https://leafcup.com/contact.html?" + `c-name=${encodeURIComponent(context.user.value.service.nickname)}&c-email=${encodeURIComponent(context.user.value.service.contactEmail)}&message=予約ID ${reservation.id} (日程: ${reservation.date} ${reservation.time}) のキャンセルをお願いします。`}
                      >
                        こちら
                      </a>
                    </small>
                  </div>
                </div>
              </div>

              <div className="card-footer text-muted small text-end">
                ご予約日: {new Date(reservation.createdAt).toLocaleString()}
              </div>
            </div>
          )}
        </div>

        <div className="text-center pt-3">
          <NavLink to={Paths.my.path}>戻る</NavLink>
        </div>
      </div>
    </div>
  </>
}
