import {ReservationId, StaffId, UserId} from "./identity";
import {DocumentSnapshotLike} from "../types/firestore";
import {BadRequestErrorResponse, BadRequestField, ConflictErrorResponse, ForbiddenErrorResponse, NotFoundErrorResponse, SuccessOKResponse} from "../response";
import {Time} from "../lib/time";

export type PaymentStatus = "tbd" | "interrupted" | "success" | "failure"

export const unsettledStatus: ReadonlyArray<PaymentStatus> = ["tbd", "interrupted"]

export class Reservation {
  constructor(
    readonly id: ReservationId,
    readonly createdAt: number,
    readonly updatedAt: number,
    readonly userId: UserId,
    readonly staffId: StaffId,
    readonly date: string,
    readonly time: string,
    readonly paymentIntentId: string,
    readonly status: PaymentStatus = "tbd",
    readonly cancelled: boolean = false,
  ) {
  }

  get statusLabel(): string {
    if (this.cancelled) {
      return "キャンセル済み"
    }
    switch (this.status) {
      case "tbd": return "決済処理中"
      case "failure": return "決済エラー"
      case "success": return "決済完了"
      case "interrupted": return "確認待ち"
    }
  }

  toDocumentData(): object {
    return {
      // id は document path に入るので入れない
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      userId: this.userId,
      staffId: this.staffId,
      date: this.date,
      time: this.time,
      paymentIntentId: this.paymentIntentId,
      status: this.status,
      cancelled: this.cancelled
    };
  }

  static fromDocumentSnapshot(doc: DocumentSnapshotLike | null): Reservation | null {
    if (!doc) {
      return null;
    }
    const data = doc.data();
    if (data === undefined) {
      return null;
    }

    return new Reservation(
      ReservationId(doc.id),
      data["createdAt"],
      data["updatedAt"],
      data["userId"],
      data["staffId"],
      data["date"],
      data["time"],
      data["paymentIntentId"],
      data["status"],
      data["cancelled"],
    );
  }

  static validate(request: CreateReservationRequest): true | BadRequestField<"operation" | "date" | "time" | "status"> {
    const errors: BadRequestField<"operation" | "date" | "time" | "status"> = [];

    if (!request.date) {
      errors.push(["date", "MISSING"])
    } else {
      const date = request.date
      // noinspection SuspiciousTypeOfGuard
      if (typeof date !== "string") {
        errors.push(["date", "TYPE_NOT_MATCH"])
      } else {
        const parsed = date.split("-");
        if (parsed.length !== 3) {
          errors.push(["date", "BAD_FORMAT"])
        } else {
          const year = parseInt(parsed[0], 10)
          const month = parseInt(parsed[1], 10);
          const day = parseInt(parsed[2], 10);
          if (isNaN(year) || isNaN(month) || isNaN(day)) {
            errors.push(["date", "BAD_FORMAT"])
          } else {
            const dateClass = new Date(`${date}T00:00:00.000Z`);
            if (dateClass.getFullYear() !== year || dateClass.getMonth() !== month - 1 || dateClass.getDate() !== day) {
              console.error(dateClass.toISOString(), date, dateClass.getFullYear(), year, dateClass.getMonth(), month, dateClass.getDate(), day)
              // 2/30 や 4/32 などの異常な日付が指定された場合など
              errors.push(["date", "BAD_FORMAT"])
            }
          }
        }
      }
    }

    if (!request.time) {
      errors.push(["time", "MISSING"])
    } else {
      const time = request.time
      // noinspection SuspiciousTypeOfGuard
      if (typeof time !== "string") {
        errors.push(["time", "TYPE_NOT_MATCH"])
      } else {
        const parsed = time.split(":");
        if (parsed.length !== 2) {
          errors.push(["time", "BAD_FORMAT"])
        } else {
          const hours = parseInt(parsed[0], 10)
          const minutes = parseInt(parsed[1], 10);
          if (isNaN(hours) || isNaN(minutes)) {
            errors.push(["time", "BAD_FORMAT"])
          } else {
            const timeClass = new Time(hours, minutes, undefined);
            if (timeClass.hours !== hours || timeClass.minutes !== minutes) {
              // 12:60 や 25:00 などの異常な時間が指定された場合など
              errors.push(["time", "BAD_FORMAT"])
            }
          }
        }
      }
    }
    return errors.length === 0 ? true : errors;
  }

  static toDateString(date: Date): string {
    return `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${("0" + date.getDate()).slice(-2)}`;
  }
}

export type CreateReservationRequest = {
  staffId: StaffId,
  date: string,
  time: string,
  paymentMethodId: string
}

export type CreateReservationResult = {
  reservationId: ReservationId
  resumption: Resumption
}

export type CreateReservationResponse =
  SuccessOKResponse<CreateReservationResult>
  | BadRequestErrorResponse<"operation" | "date" | "time" | "status">
  | ForbiddenErrorResponse
  | NotFoundErrorResponse
  | ConflictErrorResponse

export type CancelReservationRequest = {
  reservationId: ReservationId
}

export type CancelReservationResponse =
  SuccessOKResponse<{}>
  | BadRequestErrorResponse<"reason">
  | ForbiddenErrorResponse
  | NotFoundErrorResponse
  | ConflictErrorResponse


export type ResumePaymentRequest = {
  reservationId: ReservationId
}

export type Resumption =
  { status: Exclude<PaymentStatus, "interrupted"> } |
  { status: "interrupted", clientSecret: string }

export type ResumePaymentResponse =
  SuccessOKResponse<Resumption>
  | ForbiddenErrorResponse
  | NotFoundErrorResponse
  | ConflictErrorResponse
