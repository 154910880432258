import {Dispatch, SetStateAction} from "react";

export type ContextProperty<T> = {
  value: T,
  set: Dispatch<SetStateAction<T>>,
}

export function asContextProperty<T>(value: [get: T, set: Dispatch<SetStateAction<T>>]): ContextProperty<T> {
  return {value: value[0], set: value[1]}
}
