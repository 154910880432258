import React, {ReactElement, useEffect, useState} from "react";
import {Navigate, NavLink, Outlet, useOutletContext} from "react-router-dom";
import {Spinner} from "../Spinner";
import {asContextProperty, ContextProperty} from "../../lib/context-property";
import {useCommonAccessContext} from "./CommonAccessGuard";
import {Admin} from "../../../functions/src/shared/entity/admin";
import {Paths} from "../../lib/paths";
import {AdminId} from "../../../functions/src/shared/entity/identity";
import {FirestoreAccess} from "../../access/firestore-access";
import {SignUpAccessContext, SignUpAccessValue} from "./SignUpGuard";
import {Dialog} from "../Dialog";
import {LoadingOption} from "../../../functions/src/shared/types/loading";

export type AdminAccessContext = SignUpAccessContext & {
  admin: ContextProperty<Admin>,
}

export function useAdminAccessContext() {
  return useOutletContext<AdminAccessContext>()
}

export const AdminAccessOutlet = (props: { user: SignUpAccessValue, admin: Admin, dialog: Dialog }) => {
  return <div className="d-flex flex-row">
    <div className="d-flex flex-column flex-shrink-0">
      <ul className="nav nav-pills flex-column mb-auto mt-3">
        <li className="nav-item">
          <NavLink to={Paths.admin.top.path} className="nav-link" aria-current="page" end={true}>
            <i className="fas fa-fw fa-home me-sm-2"/>
            <span className="d-none d-sm-inline">Home</span>
          </NavLink>
        </li>
        <li>
          <NavLink to={Paths.admin.admins.path} className="nav-link" aria-current="page">
            <i className="fas fa-fw fa-circle-user me-sm-2"/>
            <span className="d-none d-sm-inline">Admin</span>
          </NavLink>
        </li>
        <li>
          <NavLink to={Paths.admin.users.path} className="nav-link" aria-current="page">
            <i className="fas fa-fw fa-users me-sm-2"/>
            <span className="d-none d-sm-inline">User</span>
          </NavLink>
        </li>
        <li>
          <NavLink to={Paths.admin.staffs.path} className="nav-link" aria-current="page">
            <i className="fas fa-fw fa-user-tie me-sm-2"/>
            <span className="d-none d-sm-inline">Staff</span>
          </NavLink>
        </li>
        <li>
          <NavLink to={Paths.admin.reservations.path} className="nav-link" aria-current="page">
            <i className="fas fa-fw fa-calendar me-sm-2"/>
            <span className="d-none d-sm-inline">Reservation</span>
          </NavLink>
        </li>
        <li>
          <NavLink to={Paths.admin.refund.path} className="nav-link" aria-current="page">
            <i className="fas fa-fw fa-hand-holding-dollar me-sm-2"/>
            <span className="d-none d-sm-inline">Refund</span>
          </NavLink>
        </li>
      </ul>
    </div>

    <div className="flex-grow-1">
      <div className="container-fluid">
        <Outlet context={{
          user: asContextProperty(useState<SignUpAccessValue>(props.user)),
          admin: asContextProperty(useState<Admin>(props.admin)),
          dialog: props.dialog,
        }}/>
      </div>
    </div>
  </div>
}

export function AdminGuard(): ReactElement {
  const context = useCommonAccessContext()
  const [user, setUser] = useState<LoadingOption<SignUpAccessValue>>("loading");
  const [admin, setAdmin] = useState<LoadingOption<Admin>>("loading")

  useEffect(() => {
    if (context.user.value === "loading") {
      return;
    }
    if (context.user.value === null) {
      setUser(null)
      setAdmin(null);
      return;
    }
    const service = context.user.value.service;
    const firebase = context.user.value.firebase
    if (service === null) {
      setUser(null)
      setAdmin(null);
      return;
    }
    setUser({service, firebase})
    if (context.user.value.firebase === null || context.user.value.firebase.email === null) {
      setAdmin(null);
      return;
    }
    FirestoreAccess.getAdmin(AdminId(context.user.value.firebase.email)).then(setAdmin);
  }, [context.user.value]);

  if (admin === null) {
    console.error("You are not admin!");
    return <Navigate to={Paths.login.pathWithLocation(window.location)}/>
  }

  if (user === null) {
    return <Navigate to={Paths.login.pathWithLocation(window.location)}/>
  } else if (user === "loading") {
    return <div className="text-center mt-5">
      <Spinner magnification={3}/>
    </div>
  } else if (admin === "loading") {
    return <div className="text-center mt-5">
      <Spinner magnification={3}/>
    </div>
  } else {
    return <AdminAccessOutlet user={user} admin={admin} dialog={context.dialog}/>
  }
}
