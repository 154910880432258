import React from "react";
import {Spinner} from "../../components/Spinner";
import {useStaffs} from "../../access/firestore-access";
import {NavLink} from "react-router-dom";
import {Paths} from "../../lib/paths";

export const AdminStaffs = () => {

  const staffs = useStaffs();

  return <>
    <div className="row">
      <div className="col-12 offset-xl-2 col-xl-8 text-center">
        <h1 className="page-title">Staff</h1>
      </div>

      <div className="col-12 offset-xl-2 col-xl-8 text-end">
        <NavLink to={Paths.admin.staffs.new.path} className="btn btn-primary">Create New</NavLink>
      </div>

      <div className="col-12 offset-xl-2 col-xl-8 text-center">
        <table className="table table-striped">
          <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Name</th>
            <th scope="col">Registered at</th>
            <th scope="col">Updated at</th>
            <th scope="col" className="text-center">Link</th>
          </tr>
          </thead>
          <tbody>
          {staffs.values === "loading"
            ?
            <tr>
              <td scope="row" colSpan={5}>
                <Spinner magnification={1}/>
              </td>
            </tr>
            : staffs.values.map(staff =>
              <tr key={staff.id}>
                <td scope="row" className="word-break-break-all">{staff.id}</td>
                <td scope="row" className="word-break-break-all">{staff.nickname}</td>
                <td scope="row">{new Date(staff.createdAt).toLocaleString()}</td>
                <td scope="row">{new Date(staff.updatedAt).toLocaleString()}</td>
                <td scope="row" className="text-center">
                  <NavLink to={Paths.admin.staffs.staff.getPath(staff.id)}>Detail</NavLink>
                </td>
              </tr>
            )
          }
          </tbody>
        </table>
      </div>
    </div>
  </>
}
