import "@fortawesome/fontawesome-free/css/all.css"
import "./index.scss"
import "bootstrap"
import "cropperjs/dist/cropper.css";
import React from "react";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom"
import {Paths} from "./lib/paths"
import {AdminGuard} from "./components/guards/AdminGuard";
import {AdminTop} from "./pages/admin/AdminTop";
import {CommonAccessGuard} from "./components/guards/CommonAccessGuard";
import {SignUpGuard} from "./components/guards/SignUpGuard";
import {createRoot} from "react-dom/client";
import {Top} from "./pages/Top";
import {AccountLogin} from "./pages/AccountLogin";
import {AccountRegister} from "./pages/AccountRegister";
import {User} from "./pages/User";
import {My} from "./pages/My";
import {MyProfile} from "./pages/MyProfile";
import {AccountPasswordUpdate} from "./pages/AccountPasswordUpdate";
import {AccountEmailUpdate} from "./pages/AccountEmailUpdate";
import {AccountUnregister} from "./pages/AccountUnregister";
import {AdminAdmins} from "./pages/admin/AdminAdmins";
import {AdminUsers} from "./pages/admin/AdminUsers";
import {AdminUsersDetail} from "./pages/admin/AdminUsersDetail";
import {AdminStaffs} from "./pages/admin/AdminStaffs";
import {AdminStaffsDetail} from "./pages/admin/AdminStaffsDetail";
import {AdminStaffsNew} from "./pages/admin/AdminStaffsNew";
import {Reserve} from "./pages/Reserve";
import {Reserved} from "./pages/Reserved";
import {AdminReservations} from "./pages/admin/AdminReservations";
import {AdminReservationsDetail} from "./pages/admin/AdminReservationsDetail";
import {MyReservations} from "./pages/MyReservations";
import { MyPaymentMethods } from "./pages/MyPaymentMethods";
import { AdminRefund } from "./pages/admin/AdminRefund";

const root = (
  <BrowserRouter>
    <Routes>
      <Route path={Paths.top.path} element={<CommonAccessGuard/>}>
        <Route path={Paths.admin.path} element={<AdminGuard/>}>
          <Route path={Paths.admin.top.path} element={<AdminTop/>}/>
          <Route path={Paths.admin.admins.path} element={<AdminAdmins/>}/>
          <Route path={Paths.admin.users.path} element={<AdminUsers/>}/>
          <Route path={Paths.admin.users.user.path} element={<AdminUsersDetail/>}/>
          <Route path={Paths.admin.staffs.path} element={<AdminStaffs/>}/>
          <Route path={Paths.admin.staffs.new.path} element={<AdminStaffsNew/>}/>
          <Route path={Paths.admin.staffs.staff.path} element={<AdminStaffsDetail/>}/>
          <Route path={Paths.admin.reservations.path} element={<AdminReservations/>}/>
          <Route path={Paths.admin.reservations.reservation.path} element={<AdminReservationsDetail/>}/>
          <Route path={Paths.admin.refund.path} element={<AdminRefund/>}/>
        </Route>
        <Route path={Paths.login.path} element={<AccountLogin/>}/>
        <Route path={Paths.register.path} element={<AccountRegister/>}/>
        <Route path={Paths.account.path} element={<SignUpGuard/>}>
          <Route path={Paths.account.unregister.path} element={<AccountUnregister/>}/>
          <Route path={Paths.account.update.email.path} element={<AccountEmailUpdate/>}/>
          <Route path={Paths.account.update.password.path} element={<AccountPasswordUpdate/>}/>
        </Route>
        <Route path={Paths.users.path} element={<SignUpGuard/>}>
          <Route path={Paths.users.user.path} element={<User/>}/>
        </Route>
        <Route path={Paths.my.path} element={<SignUpGuard/>}>
          <Route path={Paths.my.path} element={<My/>}/>
          <Route path={Paths.my.profile.path} element={<MyProfile/>}/>
          <Route path={Paths.my.reservations.path} element={<MyReservations/>}/>
          <Route path={Paths.my.paymentMethods.path} element={<MyPaymentMethods/>}/>
        </Route>
        <Route path={Paths.reserve.path} element={<Reserve/>}/>
        <Route path={Paths.reserved.path} element={<Reserved/>}/>
        <Route path={Paths.top.path} element={<Top/>}/>
      </Route>
      <Route path="*" element={<Navigate to="/"/>}/>
    </Routes>
  </BrowserRouter>
)

createRoot(document.getElementById("screen")!).render(root)
