import {UserId} from "./identity";
import {DocumentSnapshotLike} from "../types/firestore";
import {BadRequestErrorResponse, BadRequestField, ConflictErrorResponse, NotFoundErrorResponse, Responses, SuccessOKResponse} from "../response";

export class User {
  constructor(
    readonly id: UserId,
    readonly createdAt: number,
    readonly updatedAt: number,
    readonly name: string,
    readonly nameKana: string,
    readonly nickname: string,
    readonly gender: string,
    readonly contactEmail: string,
    readonly phone: string,
    readonly prefecture: string,
    readonly occupation: string,
    readonly how: string,
    readonly stripeCustomer?: string
  ) {
  }

  static readonly NICKNAME_MIN_LENGTH = 2;
  static readonly NICKNAME_MAX_LENGTH = 8;
  static readonly VALID_PREFECTURES = [
    "海外在住",
    "北海道",
    "青森県",
    "岩手県",
    "宮城県",
    "秋田県",
    "山形県",
    "福島県",
    "茨城県",
    "栃木県",
    "群馬県",
    "埼玉県",
    "千葉県",
    "東京都",
    "神奈川県",
    "新潟県",
    "富山県",
    "石川県",
    "福井県",
    "山梨県",
    "長野県",
    "岐阜県",
    "静岡県",
    "愛知県",
    "三重県",
    "滋賀県",
    "京都府",
    "大阪府",
    "兵庫県",
    "奈良県",
    "和歌山県",
    "鳥取県",
    "島根県",
    "岡山県",
    "広島県",
    "山口県",
    "徳島県",
    "香川県",
    "愛媛県",
    "高知県",
    "福岡県",
    "佐賀県",
    "長崎県",
    "熊本県",
    "大分県",
    "宮崎県",
    "鹿児島県",
    "沖縄県",
  ]
  static readonly VALID_OCCUPATIONS = [
    "会社員",
    "会社役員",
    "公務員",
    "自営業",
    "主夫/主婦",
    "アルバイト",
    "学生",
    "その他",
  ]
  static readonly VALID_HOWS = [
    "ネット検索",
    "Web上の広告",
    "Twitter",
    "Youtube",
    "口コミ",
    "紹介",
    "その他",
  ]

  toDocumentData(): object {
    return {
      // id は document path に入るので入れない
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      name: this.name,
      nameKana: this.nameKana,
      nickname: this.nickname,
      gender: this.gender,
      contactEmail: this.contactEmail,
      phone: this.phone,
      prefecture: this.prefecture,
      occupation: this.occupation,
      how: this.how,
      stripeCustomer: this.stripeCustomer,
    };
  }

  static fromDocumentSnapshot(doc: DocumentSnapshotLike | null): User | null {
    if (!doc) {
      return null;
    }
    const data = doc.data();
    if (data === undefined) {
      return null;
    }

    return new User(
      UserId(doc.id),
      data["createdAt"],
      data["updatedAt"],
      data["name"],
      data["nameKana"],
      data["nickname"],
      data["gender"],
      data["contactEmail"],
      data["phone"],
      data["prefecture"],
      data["occupation"],
      data["how"],
      data["stripeCustomer"],
    );
  }

  static validate(request: UpdateUserRequest): true | BadRequestField<"operation" | "nickname"> {
    const errors: BadRequestField<"operation" | "nickname"> = [];
    if (request.operation === "UNREGISTER") {
      return true;
    }

    if (request.nickname) {
      const nickname = request.nickname
      // noinspection SuspiciousTypeOfGuard
      if (typeof nickname !== "string") {
        errors.push(["nickname", "TYPE_NOT_MATCH"])
      } else {
        if (nickname.length <= User.NICKNAME_MIN_LENGTH) {
          errors.push(["nickname", "TOO_SHORT"])
        } else if (User.NICKNAME_MAX_LENGTH < nickname.length) {
          errors.push(["nickname", "TOO_LONG"])
        }
        if (!Responses.messages.ascii(nickname)) {
          errors.push(["nickname", "INVALID_CHARACTER"])
        }
      }
    } else {
      errors.push(["nickname", "MISSING"])
    }

    return errors.length === 0 ? true : errors;
  }
}

export type UserMeta = {
  name: string,
  nameKana: string,
  nickname: string,
  gender: string,
  contactEmail: string,
  phone: string,
  prefecture: string,
  occupation: string,
  how: string,
}

export type UpdateUserRequestRegister = UserMeta & {
  operation: "REGISTER",
}

export type UpdateUserRequestModify = UserMeta & {
  operation: "MODIFY",
}

export type UpdateUserRequestUnregister = {
  operation: "UNREGISTER",
}

export type UpdateUserRequest = UpdateUserRequestRegister | UpdateUserRequestModify | UpdateUserRequestUnregister

export type UpdateUserResponse = SuccessOKResponse<User>
  | BadRequestErrorResponse<"operation" | "nickname">
  | NotFoundErrorResponse
  | ConflictErrorResponse
