import {useParams} from "react-router-dom";
import {ReservationId, StaffId, UserId} from "../../functions/src/shared/entity/identity";
import {Reservation} from "../../functions/src/shared/entity/reservation";

export class Paths {

  public static readonly top = {
    path: "/",
  }

  public static readonly reserve = {
    path: "/reserve/:staffId/:date/:time",
    getPath: (staffId: StaffId, date: Date, time: string) => `/reserve/${staffId}/${Reservation.toDateString(date)}/${encodeURIComponent(time)}`,
    usePath: useParams as () => { staffId: StaffId, date: string, time: string },
  }

  public static readonly reserved = {
    path: "/reserved/:reservationId",
    getPath: (reservationId: ReservationId) => `/reserved/${reservationId}`,
    usePath: useParams as () => { reservationId: ReservationId },
  }

  public static readonly my = {
    path: "/my",

    profile: {
      path: "/my/profile",
    },

    reservations: {
      path: "/my/reservations",
    },

    paymentMethods: {
      path: "/my/payment-methods",
    },
  }

  public static readonly users = {
    path: "/users",

    user: {
      path: "/users/:userId",
      usePath: useParams as () => Record<"userId", UserId>,
    },
  }

  public static readonly login = {
    path: "/login",
    pathWithFrom(path: string): string {
      return `/login?from=${encodeURIComponent(path)}`
    },
    pathWithLocation(location: Location): string {
      return Paths.login.pathWithFrom(location.pathname + location.search)
    },
  }

  public static readonly register = {
    path: "/register",
    pathWithFrom(path: string): string {
      return `/register?from=${encodeURIComponent(path)}`
    },
    pathWithLocation(location: Location): string {
      return Paths.register.pathWithFrom(location.pathname + location.search)
    },
  }

  public static readonly account = {
    path: "/account",
    unregister: {
      path: "/account/unregister",
    },
    update: {
      path: "/account/update",
      email: {
        path: "/account/update/email",
      },
      password: {
        path: "/account/update/password",
      },
    },
  }

  public static readonly admin = {
    path: "/admin",
    top: {
      path: "/admin"
    },
    admins: {
      path: "/admin/admins"
    },
    users: {
      path: "/admin/users",

      user: {
        path: "/admin/users/:userId",
        getPath: (userId: UserId) => `/admin/users/${userId}`,
        usePath: useParams as () => Record<"userId", UserId>,
      },
    },
    staffs: {
      path: "/admin/staffs",

      new: {
        path: "/admin/staffs/new",
      },

      staff: {
        path: "/admin/staffs/:staffId",
        getPath: (staffId: StaffId) => `/admin/staffs/${staffId}`,
        usePath: useParams as () => Record<"staffId", StaffId>,
      },
    },
    reservations: {
      path: "/admin/reservations",

      reservation: {
        path: "/admin/reservations/:reservationId",
        getPath: (reservationId: ReservationId) => `/admin/reservations/${reservationId}`,
        usePath: useParams as () => Record<"reservationId", ReservationId>,
      },
    },
    refund: {
      path: "/admin/refund"
    }
  }

  public static from(value: string | undefined, defaultPath: string): string {
    if (value === undefined) {
      return defaultPath
    }
    const decoded = decodeURIComponent(value)
    if (decoded.slice(0, 1) !== "/") {
      return defaultPath
    }
    return decoded
  }

  public static readonly titles = new Map([
    [Paths.my.path + "/*", "マイページ"],
    [Paths.users.path + "/*", "ユーザーページ"],
    [Paths.login.path + "/*", "ログイン"],
    [Paths.register.path + "/*", "登録"],
    [Paths.account.unregister.path + "/*", "退会"],
    [Paths.account.update.email.path + "/*", "メールアドレス変更"],
    [Paths.account.update.password.path + "/*", "パスワード変更"],
    [Paths.admin.path + "/*", "管理ツール"],
    [Paths.top.path, "Online Chat"],
  ])
}
