import {StaffId} from "./identity";
import {DocumentSnapshotLike} from "../types/firestore";
import {BadRequestErrorResponse, BadRequestField, ConflictErrorResponse, NotFoundErrorResponse, Responses, SuccessOKResponse} from "../response";

export type StaffSlot = { day: number, time: string, }

export class Staff {
  constructor(
    readonly id: StaffId,
    readonly createdAt: number,
    readonly updatedAt: number,
    readonly name: string,
    readonly nickname: string,
    readonly email: string,
    readonly country: string,
    readonly phone: string,
    readonly birthday: string,
    readonly gender: string,
    readonly visaStatus: string,
    readonly station: string,
    readonly experience: string,
    readonly introduction?: string,
    readonly note?: string,
    readonly photoURL?: string,
    readonly languages?: {
      value: string,
    }[],
    readonly roomURL?: string,
    readonly slots?: StaffSlot[],
  ) {
  }

  static readonly NICKNAME_MIN_LENGTH = 2;
  static readonly NICKNAME_MAX_LENGTH = 32;
  static readonly VALID_LANGUAGES = [
    "英語",
    "スペイン語",
    "ドイツ語",
    "フランス語",
    "イタリア語",
    "ポルトガル語",
    "ロシア語",
    "アラビア語",
    "ヒンディー語",
    "中国語",
    "広東語",
    "韓国語",
    "日本語",
  ];
  static readonly VALID_DAYS = [0, 1, 2, 3, 4, 5, 6]
  static readonly VALID_VISA_STATUSES = [
    "Specialist-In-Humanities",
    "Spouse-of-Japanese-National",
    "Instructor",
    "Working-Holiday",
    "Artist",
    "Entertainer",
    "College-Student",
    "Trainee",
    "Dependent",
    "Permanent-Visa",
    "Japanese-Citizen",
  ]
  static readonly VALID_ENGLISH_TEACHING_EXPERIENCES = [
    "None",
    "Less than 3years",
    "More than 3years",
  ]
  static readonly VALID_COUNTRIES = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "AntiguaDeps",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "BosniaHerzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cape",
    "Verde",
    "CentralAfricanRep",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo",
    "Costa",
    "Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    "CzechRepublic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "DominicanRepublic",
    "EastTimor",
    "Ecuador",
    "Egypt",
    "ElSalvador",
    "Equatorial",
    "Guinea",
    "Eritrea",
    "Estonia",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Ivory",
    "Coast",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "KoreaNorth",
    "KoreaSouth",
    "Kosovo",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macedonia",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall-Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "NewZealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Panama",
    "PapuaNewGuinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "StKittsNevis",
    "StLucia",
    "SaintVincentTheGrenadines",
    "Samoa",
    "SanMarino",
    "SaoTomePrincipe",
    "SaudiArabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "SierraLeone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "SolomonIslands",
    "Somalia",
    "SouthAfrica",
    "SouthSudan",
    "Spain",
    "SriLanka",
    "Sudan",
    "Suriname",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Togo",
    "Tonga",
    "TrinidadTobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United ArabEmirates",
    "United Kingdom",
    "United States",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "VaticanCity",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ]

  toDocumentData(): object {
    return {
      // id は document path に入るので入れない
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      name: this.name,
      nickname: this.nickname,
      email: this.email,
      country: this.country,
      phone: this.phone,
      birthday: this.birthday,
      gender: this.gender,
      visaStatus: this.visaStatus,
      station: this.station,
      experience: this.experience,
      introduction: this.introduction,
      note: this.note,
      photoURL: this.photoURL,
      languages: this.languages,
      roomURL: this.roomURL,
      slots: this.slots,
    };
  }

  static fromDocumentSnapshot(doc: DocumentSnapshotLike | null): Staff | null {
    if (!doc) {
      return null;
    }
    const data = doc.data();
    if (data === undefined) {
      return null;
    }

    return new Staff(
      StaffId(doc.id),
      data["createdAt"],
      data["updatedAt"],
      data["name"],
      data["nickname"],
      data["email"],
      data["country"],
      data["phone"],
      data["birthday"],
      data["gender"],
      data["visaStatus"],
      data["station"],
      data["experience"],
      data["introduction"],
      data["note"],
      data["photoURL"],
      data["languages"],
      data["roomURL"],
      data["slots"],
    );
  }

  static validate(request: UpdateStaffRequest): true | BadRequestField<"operation" | "nickname"> {
    if (request.operation === "REMOVE") {
      return true;
    }

    const errors: BadRequestField<"operation" | "nickname"> = [];

    if (request.nickname) {
      const nickname = request.nickname
      // noinspection SuspiciousTypeOfGuard
      if (typeof nickname !== "string") {
        errors.push(["nickname", "TYPE_NOT_MATCH"])
      } else {
        if (nickname.length <= Staff.NICKNAME_MIN_LENGTH) {
          errors.push(["nickname", "TOO_SHORT"])
        } else if (Staff.NICKNAME_MAX_LENGTH < nickname.length) {
          errors.push(["nickname", "TOO_LONG"])
        }
        if (!Responses.messages.ascii(nickname)) {
          errors.push(["nickname", "INVALID_CHARACTER"])
        }
      }
    } else {
      errors.push(["nickname", "MISSING"])
    }

    return errors.length === 0 ? true : errors;
  }

  static getDayOfWeek(value: number): string {
    switch (value) {
      case 0:
        return "Sun"
      case 1:
        return "Mon"
      case 2:
        return "Tue"
      case 3:
        return "Wed"
      case 4:
        return "Thu"
      case 5:
        return "Fri"
      case 6:
        return "Sat"
      default:
        throw new Error(`unknown day: ${value}`)
    }
  }

  static getDateLabel(value: Date): string {
    return `${(value.getMonth() + 1)}/${value.getDate()}(${Staff.getDayOfWeek(value.getDay()).toUpperCase()})`
  }
}

export type StaffMeta = {
  name: string,
  nickname: string,
  email: string,
  country: string,
  phone: string,
  birthday: string,
  gender: string,
  visaStatus: string,
  station: string,
  experience: string,
  introduction?: string,
  note?: string,
  photoURL?: string,
  languages?: { value: string }[],
  roomURL?: string,
  slots?: StaffSlot[],
}

export type UpdateStaffRequestAdd = StaffMeta & {
  operation: "ADD",
}

export type UpdateStaffRequestModify = StaffMeta & {
  operation: "MODIFY",
  staffId: StaffId,
}

export type UpdateStaffRequestRemove = {
  operation: "REMOVE",
  staffId: StaffId,
}

export type UpdateStaffRequest = UpdateStaffRequestAdd | UpdateStaffRequestModify | UpdateStaffRequestRemove

export type UpdateStaffResponse = SuccessOKResponse<{id: StaffId}>
  | BadRequestErrorResponse<"operation" | "nickname">
  | NotFoundErrorResponse
  | ConflictErrorResponse
