import * as Rx from "rxjs";
import {map} from "rxjs/operators";
import {ajax, AjaxResponse} from "rxjs/ajax";

export class ApiClient {

  static readonly CONTENT_TYPES = {
    APPLICATION_JSON: {"Content-Type": "application/json"},
    IMAGE_PNG: {"Content-Type": "image/png"},
  }

  static fetchRaw(url: string, method: string, headers: { [_: string]: string }, body: object): Rx.Observable<Response> {
    return Rx.from(fetch(url, {
      method,
      headers: new Headers(headers),
      mode: "cors",
      body: JSON.stringify(body),
    }))
  }

  static fetchAsText(url: string, headers?: { [_: string]: string }): Rx.Observable<string> {
    return ApiClient.request<string>('GET', url, headers, undefined, 'text').pipe(
      map(x => x.response),
    )
  }

  static fetch<T>(url: string, headers?: { [_: string]: string }): Rx.Observable<T> {
    return ApiClient.request<T>('GET', url, headers, undefined, 'json').pipe(
      map(x => x.response),
      ApiClient.errorHandler<T>(),
    )
  }

  static update<T>(method: 'POST' | 'PUT' | 'DELETE' | 'PATCH', url: string, headers?: { [_: string]: string }, body?: any): Rx.Observable<T> {
    return ApiClient.request<T>(method, url, headers, body, 'json').pipe(
      map(x => x.response),
      ApiClient.errorHandler<T>(),
    )
  }

  static request<T>(method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH', url: string, headers?: { [_: string]: string }, body?: any, responseType?: 'text' | 'json'): Rx.Observable<AjaxResponse<T>> {
    return ajax({method, url, body, responseType, headers})
  }

  private static errorHandler<T>(): Rx.OperatorFunction<T, T> {
    return Rx.catchError(e => {
      console.error(e.response)
      if (e.response && e.response.status && e.response.value && e.response.value.kind && e.response.value.message) {
        return Rx.of(e.response) as Rx.Observable<T>;
      }
      console.error(e);
      throw e;
    })
  }

}
