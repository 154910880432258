import { useEffect, useRef, useState } from "react";

export function usePromise<T>(src: () => Promise<T> | undefined, deps: ReadonlyArray<any> = []): T | "loading" | "error" {
  const [value, setValue] = useState<T | "error">()
  const loader = useRef<Promise<T>>()
  useEffect(() => {
    setValue(undefined)
    const promise = src()
    loader.current = promise
    loader.current?.then(value => {
      if (promise === loader.current) {
        setValue(value)
      }
    }).catch(e => {
      console.error(e);
      setValue("error")
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
  return value ?? "loading"
}
