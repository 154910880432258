import React from "react";
import * as Rx from "rxjs";
import {useAdminAccessContext} from "../../components/guards/AdminGuard";
import {Spinner} from "../../components/Spinner";
import {useAdmins} from "../../access/firestore-access";
import {Admin} from "../../../functions/src/shared/entity/admin";
import {useForm} from "react-hook-form";
import {core} from "../../core";
import {Responses} from "../../../functions/src/shared/response";
import {publicConfig} from "../../../functions/src/shared/public-config";
import {toMap} from "../../../functions/src/shared/lib/util";
import {AdminForm} from "../../components/form/AdminForm";

export const AdminAdmins = () => {

  const limit = publicConfig.env === "local" ? 2 : 50;
  const context = useAdminAccessContext()
  const admins = useAdmins(limit);
  const formDelete = useForm({mode: "onBlur"})

  function onDelete(admin: Admin) {
    if (!confirm(admin.id + "を管理者から削除します。本当によろしいですか？")) {
      return;
    }
    return Rx.firstValueFrom(core.api.removeAdmin(context.user.value.firebase, admin.id))
      .then(response => {
        if (response.status === 200) {
          admins.reload()
        } else if (response.status === 400) {
          for (const [key, value] of toMap(response.value.errors)) {
            if (key === "operation") {
              formDelete.setError("root", {message: Responses.messages.systemError})
            } else {
              formDelete.setError(key, {message: Responses.messages.convert(value)})
            }
          }
        } else if (400 < response.status) {
          formDelete.setError("adminId", {message: response.value.message})
        } else {
          console.error(response)
          formDelete.setError("adminId", {message: Responses.messages.systemError});
        }
      }).catch(e => {
        console.error(e)
        formDelete.setError("adminId", {message: Responses.messages.systemError});
      });
  }

  return <>
    <div className="row">
      <div className="col-12 offset-xl-2 col-xl-8 text-center">
        <h1 className="page-title">Admin</h1>
      </div>

      <div className="col-12 offset-xl-2 col-xl-8">
        <AdminForm firebaseUser={context.user.value.firebase} admins={admins}/>

        <table className="table table-striped">
          <thead>
          <tr>
            <th scope="col">Email</th>
            <th scope="col" className="text-center">Action</th>
          </tr>
          </thead>
          <tbody>
          {admins.values === "loading"
            ?
            <tr>
              <td scope="row" colSpan={2}>
                <Spinner magnification={1}/>
              </td>
            </tr>
            : admins.values.map(admin =>
              <tr key={admin.id}>
                <td scope="row" className="align-middle">{admin.id}</td>
                <td scope="row" className="text-center">
                  <button className="btn btn-danger" onClick={() => onDelete(admin)}
                          disabled={context.admin.value.id === admin.id}>
                    <i className="me-2 fa-fw fas fa-xmark"></i>
                    削除
                  </button>
                </td>
              </tr>
            )
          }
          {admins.next.exists && (
            <tr>
              <td scope="row" className="text-center bg-light cursor-pointer-opacity" colSpan={2}
                  onClick={admins.next.load}>
                {limit}件を追加
              </td>
            </tr>
          )}
          </tbody>
        </table>
      </div>
    </div>
  </>
}
