import React from "react";
import {useReservations, useStaff, useUser} from "../../access/firestore-access";
import {Spinner} from "../../components/Spinner";
import {Reservation} from "../../../functions/src/shared/entity/reservation";
import {NavLink} from "react-router-dom";
import {Paths} from "../../lib/paths";

export const AdminReservations = () => {

  const limit = 50;
  const reservations = useReservations(limit);

  return <>
    <div className="row">
      <div className="col-12 text-center">
        <h1 className="page-title">Reservation</h1>
      </div>

      <div className="col-12 text-center">
        <table className="table table-striped">
          <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Staff</th>
            <th scope="col">User</th>
            <th scope="col">Date</th>
            <th scope="col">Time</th>
            <th scope="col">Created at</th>
            <th scope="col">Updated at</th>
            <th scope="col">Status</th>
            <th scope="col" className="text-center">Action</th>
          </tr>
          </thead>
          <tbody>
          {reservations.values === "loading"
            ?
            <tr>
              <td scope="row" colSpan={5}>
                <Spinner magnification={1}/>
              </td>
            </tr>
            : reservations.values.map(reservation =>
              <AdminReservationTableRow key={reservation.id} reservation={reservation}/>
            )
          }
          {reservations.next.exists && (
            <tr>
              <td
                scope="row"
                colSpan={5}
                className="text-center bg-light cursor-pointer-opacity"
                onClick={reservations.next.load}
              >
                {limit}件を追加
              </td>
            </tr>
          )}
          </tbody>
        </table>
      </div>
    </div>
  </>
}

export const AdminReservationTableRow = (props: { reservation: Reservation }) => {

  const reservation = props.reservation;
  const staff = useStaff(reservation.staffId);
  const user = useUser(reservation.userId)

  return <>
    <tr>
      <td scope="row">
        {reservation.id}
      </td>
      <td scope="row" className="word-break-break-all">
        {staff === "loading" ? <Spinner magnification={1}/> : staff?.nickname}
      </td>
      <td scope="row" className="word-break-break-all">
        {user === "loading" ? <Spinner magnification={1}/> : user?.nickname}
      </td>
      <td scope="row">
        {reservation.date}
      </td>
      <td scope="row">
        {reservation.time}
      </td>
      <td scope="row">
        {new Date(reservation.createdAt).toLocaleString()}
      </td>
      <td scope="row">
        {new Date(reservation.updatedAt).toLocaleString()}
      </td>
      <td scope="row">
        {reservation.statusLabel}
      </td>
      <td scope="row" className="text-center">
        <NavLink to={Paths.admin.reservations.reservation.getPath(reservation.id)}>詳細</NavLink>
      </td>
    </tr>
  </>
}
