export class Query {

  private raw: string;
  private queries: { [_: string]: string };

  constructor(search: string) {
    this.raw = search;
    this.queries = Query.splitQuery(this.raw);
  }

  private static buildQuery(obj: { [_: string]: string | number | boolean | undefined | null }): string {
    return Object.keys(obj).map(k => {
      const value = obj[k];
      return value === undefined
        ? null
        : value === null
          ? `${encodeURIComponent(k)}=`
          : `${encodeURIComponent(k)}=${encodeURIComponent(value)}`;
    }).filter(x => x !== null).join("&");
  }

  private static splitQuery(raw: string): { [_: string]: string } {
    const queries: { [s: string]: string } = {};
    const list = raw.split("&");
    list.forEach((value: string) => {
      const x = value.split("=");
      if (x.length <= 0) {
        return;
      }
      queries[x[0]] = x[1] ? x[1] : "";
    });
    return queries;
  }

  find(key: string): string | undefined {
    return this.queries[key];
  }

  get(key: string, defaultValue: string): string {
    return this.find(key) ?? defaultValue;
  }

  getAsOpt(key: string, defaultValue: string | undefined = undefined): string | undefined {
    return this.find(key) ?? defaultValue;
  }

  toObject(): { [_: string]: string | undefined } {
    const value = this.queries;
    Object.keys(this.queries).forEach(key => {
      value[key] = decodeURIComponent(this.queries[key]);
    });
    return value;
  }

  toString(): string {
    return Query.buildQuery(this.queries);
  }
}
