import React, {useState} from "react";
import {useSignUpAccessContext} from "../components/guards/SignUpGuard";
import {UserProfileForm} from "../components/form/UserProfileForm";
import {NavLink} from "react-router-dom";
import {Paths} from "../lib/paths";

export const MyProfile = () => {

  const context = useSignUpAccessContext();
  const [completed, setCompleted] = useState<boolean>(false);

  return <>
    <div className="row">
      <div className="col-12 text-center">
        <h1 className="page-title">プロフィールの更新</h1>
      </div>
    </div>

    <div className="row mt-5">
      <div className="col-12 offset-sm-2 col-sm-8 col-lg-4 offset-lg-4 pt-3">
        <div className="d-flex flex-column gap-3">
          <UserProfileForm
            user={context.user.value}
            onSuccess={(user) => {
              context.user.set({firebase: context.user.value.firebase, service: user})
              setCompleted(true);
              setTimeout(() => {
                setCompleted(false);
              }, 5000);
            }}
          />

          {completed && (
            <div className="text-success">更新に成功しました。</div>
          )}
        </div>

        <div className="text-center pt-3">
          <NavLink to={Paths.my.path}>戻る</NavLink>
        </div>
      </div>
    </div>
  </>
}
