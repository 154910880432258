import React, {ReactElement, useEffect, useState} from "react";
import {Navigate, Outlet, useOutletContext} from "react-router-dom";
import {Spinner} from "../Spinner";
import {asContextProperty, ContextProperty} from "../../lib/context-property";
import {CommonAccessContext, useCommonAccessContext} from "./CommonAccessGuard";
import {User} from "../../../functions/src/shared/entity/user";
import {User as FirebaseUser} from "@firebase/auth";
import {Paths} from "../../lib/paths";
import {Dialog} from "../Dialog";
import {LoadingOption} from "../../../functions/src/shared/types/loading";

export type SignUpAccessValue = { service: User, firebase: FirebaseUser }

export type SignUpAccessContext = CommonAccessContext & {
  user: ContextProperty<SignUpAccessValue>,
}

export function useSignUpAccessContext() {
  return useOutletContext<SignUpAccessContext>()
}

export const SignUpOutlet = (props: { user: SignUpAccessValue, dialog: Dialog }) => {
  return <Outlet context={{
    user: asContextProperty(useState<SignUpAccessValue>(props.user)),
    dialog: props.dialog,
  }}/>
}

export function SignUpGuard(): ReactElement {
  const context = useCommonAccessContext()
  const [user, setUser] = useState<LoadingOption<SignUpAccessValue>>("loading");

  useEffect(() => {
    if (context.user.value === "loading") {
      return;
    }
    if (context.user.value === null) {
      setUser(null)
      return;
    }
    const service = context.user.value.service;
    const firebase = context.user.value.firebase

    if (service === null) {
      setUser(null)
      return;
    }
    setUser({service, firebase})
  }, [context.user.value]);

  if (user === null) {
    return <Navigate to={Paths.login.pathWithLocation(window.location)}/>
  } else if (user === "loading") {
    return <div className="text-center mt-5">
      <Spinner magnification={3}/>
    </div>
  } else {
    return <SignUpOutlet user={user} dialog={context.dialog}/>
  }
}
