import {publicConfigCommon} from "./public-config-common";

export const publicConfig = {
  ...publicConfigCommon,
  env: "development",
  firebase: {
    apiKey: "AIzaSyDZrV1uy7ZKnL1U-291ivMwyitMoqqWiA0",
    authDomain: "chat.dev.leafcup.com",
    projectId: "leafcup-cafe-dev",
    storageBucket: "leafcup-cafe-dev.appspot.com",
    messagingSenderId: "351019722159",
    appId: "1:351019722159:web:76f42891b3e75e8151bb5a",
    measurementId: "G-Q0G7XC39YV"
  },

  stripe: {
    publicKey: "pk_test_51OPpaDDEAu0TTt8MT4sgp2pr4PuHV6IPXR1YC7agd7goAS9W0ww2oDByQiDXX81ky4ZT2ayZBOgeD0KUAs6nDQBg00QCN0UL3k",
  },
}
