import React from "react";
import {useRefundableReservations, useStaff, useUser} from "../../access/firestore-access";
import {Spinner} from "../../components/Spinner";
import {Reservation} from "../../../functions/src/shared/entity/reservation";
import {NavLink} from "react-router-dom";
import {Paths} from "../../lib/paths";
import {publicConfig} from "../../../functions/src/shared/public-config-template";

export const AdminRefund = () => {

  const limit = 50;
  const reservations = useRefundableReservations(limit);

  return <>
    <div className="row">
      <div className="col-12 text-center">
        <h1 className="page-title">返金対象の予約一覧</h1>
      </div>

      <div className="col-12 text-center">
        <table className="table table-striped">
          <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Payment Intent</th>
            <th scope="col">ステータス</th>
            <th scope="col">ユーザー</th>
            <th scope="col">スタッフ</th>
            <th scope="col">予約日</th>
            <th scope="col">作成日</th>
            <th scope="col">更新日</th>
            <th scope="col" className="text-center">Action</th>
          </tr>
          </thead>
          <tbody>
          {reservations.values === "loading"
            ?
            <tr>
              <td scope="row" colSpan={100}>
                <Spinner magnification={1}/>
              </td>
            </tr>
            : reservations.values.map(reservation =>
              <TableRow key={reservation.id} reservation={reservation}/>
            )
          }
          {reservations.next.exists && (
            <tr>
              <td
                scope="row"
                colSpan={5}
                className="text-center bg-light cursor-pointer-opacity"
                onClick={reservations.next.load}
              >
                {limit}件を追加
              </td>
            </tr>
          )}
          </tbody>
        </table>
      </div>
    </div>
  </>
}

const TableRow = (props: { reservation: Reservation }) => {

  const reservation = props.reservation;
  const staff = useStaff(reservation.staffId);
  const user = useUser(reservation.userId);
  if (staff === "loading" || user === "loading") {
    return (
      <tr>
        <td colSpan={100}><Spinner magnification={3}/></td>
      </tr>
    )
  }

  return <>
    <tr>
      <td scope="row">
        {reservation.id}
      </td>
      <td scope="row">
        <a target="_blank" rel="noopener noreferrer" href={`https://dashboard.stripe.com/${publicConfig.env === "prod" ? "" : "test/"}payments/${reservation.paymentIntentId}`}>
          {reservation.paymentIntentId}
        </a>
      </td>
      <td scope="row">
        {reservation.statusLabel}
      </td>
      <td scope="row">
        {user?.nickname ?? "(不明)"}
      </td>
      <td scope="row">
        {staff?.nickname ?? "(不明)"}
      </td>
      <td scope="row">
        {`${reservation.date} ${reservation.time}`}
      </td>
      <td scope="row">
        {new Date(reservation.createdAt).toLocaleString()}
      </td>
      <td scope="row">
        {new Date(reservation.updatedAt).toLocaleString()}
      </td>
      <td scope="row" className="text-center">
        <NavLink to={Paths.admin.reservations.reservation.getPath(reservation.id)}>詳細</NavLink>
      </td>
    </tr>
  </>
}
