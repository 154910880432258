import {useForm} from "react-hook-form";
import {core} from "../core";
import React, {useState} from "react";
import {Spinner} from "./Spinner";
import {Responses} from "../../functions/src/shared/response";

type EmailForm = {
  email: string,
}

export const LoginPasswordReset = () => {

  const id = "login-password-reset";
  const form = useForm<EmailForm>({mode: "onBlur"})
  const [completed, setCompleted] = useState<boolean>(false);

  const onSubmit = (value: EmailForm) => {
    return core.auth.sendPasswordResetEmail(value.email)
      .then(() => setCompleted(true))
      .catch(e => {
        if (e.code === "auth/invalid-email") {
          form.setError("email", {message: "メールアドレスの形式が正しくありません。"});
          return;
        } else {
          console.error(e);
          form.setError("root", {message: Responses.messages.systemError})
        }
      });
  }

  if (completed) {
    return <div>
      <span className="text-success">パスワードリセット用のメールを送信しました。メールボックスをご確認下さい。</span>
    </div>
  }

  return <>
    <span className="link-like" data-bs-toggle="collapse" data-bs-target={"#" + id} aria-expanded="false" aria-controls="collapse">
      パスワードを忘れた方はこちら
    </span>

    <div className="collapse" id={id}>
      <form className="d-flex flex-column gap-3" onSubmit={form.handleSubmit(onSubmit)}>
      <div>
          <div className="form-floating">
            <input
              id="email"
              className="form-control"
              disabled={form.formState.isSubmitting}
              {...form.register("email", {required: Responses.messages.required})}
            />
            <label htmlFor="email">メールアドレス</label>
          </div>

          {form.formState.errors.email?.message &&
            <div className="text-danger">{form.formState.errors.email.message}</div>
          }
        </div>

        <button className="form-control btn btn-primary" disabled={form.formState.isSubmitting}>
          {form.formState.isSubmitting
            ? <Spinner magnification={1}/>
            : <>メールを送信</>
          }
        </button>

        {form.formState.errors.root?.message &&
          <div className="text-danger">{form.formState.errors.root.message}</div>
        }
      </form>
    </div>
  </>
}
