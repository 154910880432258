import React, {useId} from "react";
import * as Rx from "rxjs";
import {useFieldArray, useForm} from "react-hook-form";
import {core} from "../../core";
import {Spinner} from "../Spinner";
import {User as FirebaseUser} from "@firebase/auth";
import {toMap} from "../../../functions/src/shared/lib/util";
import {Responses} from "../../../functions/src/shared/response";
import {Staff, StaffMeta} from "../../../functions/src/shared/entity/staff";
import {Navigate} from "react-router-dom";
import {Paths} from "../../lib/paths";

export const StaffForm = (props: { firebaseUser: FirebaseUser, previous: Staff | null }) => {

  const id = useId();
  const query = new URLSearchParams(window.location.search)
  const form = useForm<StaffMeta>({
    mode: "onBlur",
    defaultValues: props.previous ?? {
      name: query.get("name") ?? "",
      nickname: query.get("nickname") ?? "",
      email: query.get("email") ?? "",
      phone: query.get("phone") ?? "",
      gender: query.get("gender") ?? "",
      station: query.get("station") ?? "",
      introduction: query.get("introduction") ?? "",
      country: query.get("country") ?? "",
      birthday: query.get("birthday") ?? new Date(new Date().setFullYear(new Date().getFullYear() - 20)).getFullYear() + "-01-01",
      visaStatus: query.get("visaStatus") ?? "",
      experience: query.get("experience") ?? "",
      languages: query.get("languages")?.split(",").map(value => ({value})) ?? [{value: "英語"}],
    }
  })
  const languages = useFieldArray({control: form.control, name: "languages"})
  const slots = useFieldArray({control: form.control, name: "slots"})

  const onSubmit = (value: StaffMeta) => {
    if (value.gender === null || value.gender === "") {
      form.setError("gender", Responses.messages.required);
      return;
    }
    return Rx.firstValueFrom(core.api.updateStaff(
      props.firebaseUser,
      props.previous ?
        {...value, operation: "MODIFY", staffId: props.previous.id}
        : {...value, operation: "ADD"}
    ))
      .then(response => {
        if (response.status === 200) {
          return;
        } else if (response.status === 400) {
          for (const [key, value] of toMap(response.value.errors)) {
            if (key === "operation") {
              form.setError("root", {message: Responses.messages.systemError})
            } else {
              form.setError(key, {message: Responses.messages.convert(value)})
            }
          }
        } else if (400 < response.status) {
          form.setError("root", {message: response.value.message})
        } else {
          console.error(response)
          form.setError("root", {message: Responses.messages.systemError});
        }
      }).catch(e => {
        console.error(e)
        form.setError("root", {message: Responses.messages.systemError});
      });
  }

  if (form.formState.isSubmitSuccessful) {
    return <Navigate to={Paths.admin.staffs.path}/>
  }

  form.register("photoURL");
  const photoURL = form.watch("photoURL");

  const onChange = (e: any) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      form.setValue("photoURL", reader.result as any);
    };
    reader.readAsDataURL(files[0]);
  };

  return <>
    <form className="d-flex flex-column gap-3" onSubmit={form.handleSubmit(onSubmit)}>
      <div>
        <label htmlFor={id + "photoURL"} className="form-label">Image</label>

        {photoURL && (
          <div className="text-center my-2">
            <img src={photoURL} alt="Upload image" className="mw-100" style={{width: "250px"}}/>
          </div>
        )}

        <input
          type="file"
          id={id + "photoURL"}
          className="form-control"
          placeholder="photoURL"
          disabled={form.formState.isSubmitting}
          onChange={onChange}
        />

        {form.formState.errors.photoURL?.message &&
          <span className="text-danger">{form.formState.errors.photoURL.message}</span>
        }
      </div>

      <div>
        <label htmlFor={id + "name"} className="form-label">First and Last Name</label>
        <input
          id={id + "name"}
          className="form-control"
          placeholder="name"
          disabled={form.formState.isSubmitting}
          {...form.register("name", {required: Responses.messages.required})}
        />

        {form.formState.errors.name?.message &&
          <span className="text-danger">{form.formState.errors.name.message}</span>
        }
      </div>

      <div>
        <label htmlFor={id + "nickname"} className="form-label">Preferred Name</label>
        <input
          id={id + "nickname"}
          className="form-control"
          placeholder="nickname"
          disabled={form.formState.isSubmitting}
          {...form.register("nickname", {required: Responses.messages.required})}
        />

        {form.formState.errors.nickname?.message &&
          <span className="text-danger">{form.formState.errors.nickname.message}</span>
        }
      </div>

      <div>
        <label htmlFor={id + "email"} className="form-label">E-mail Address</label>
        <input
          id={id + "email"}
          className="form-control"
          placeholder="email"
          disabled={form.formState.isSubmitting}
          {...form.register("email", {required: Responses.messages.required})}
        />

        {form.formState.errors.email?.message &&
          <span className="text-danger">{form.formState.errors.email.message}</span>
        }
      </div>

      <div>
        <label htmlFor={id + "country"} className="form-label">Country of origin</label>
        <select
          id={id + "country"}
          className="form-control"
          placeholder="country"
          disabled={form.formState.isSubmitting}
          {...form.register("country", {required: Responses.messages.required})}
        >
          <option value="">Select country</option>
          {Staff.VALID_COUNTRIES.map(x =>
            <option key={x} value={x}>{x}</option>
          )}
        </select>

        {form.formState.errors.country?.message &&
          <span className="text-danger">{form.formState.errors.country.message}</span>
        }
      </div>

      <div>
        <label htmlFor={id + "phone"} className="form-label">Phone Number</label>
        <input
          id={id + "phone"}
          className="form-control"
          placeholder="phone"
          disabled={form.formState.isSubmitting}
          {...form.register("phone", {required: Responses.messages.required})}
        />

        {form.formState.errors.phone?.message &&
          <span className="text-danger">{form.formState.errors.phone.message}</span>
        }
      </div>

      <div>
        <label htmlFor={id + "birthday"} className="form-label">Birthday</label>
        <input
          id={id + "birthday"}
          className="form-control"
          placeholder="birthday"
          type="date"
          disabled={form.formState.isSubmitting}
          {...form.register("birthday", {required: Responses.messages.required})}
        />

        {form.formState.errors.birthday?.message &&
          <span className="text-danger">{form.formState.errors.birthday.message}</span>
        }
      </div>

      <div>
        <label htmlFor={id + "gender"} className="form-label">Gender</label>
        <div>
          <div className="form-check form-check-inline">
            <input
              type="radio"
              value="M"
              id={id + "gender-M"}
              className="form-check-input"
              placeholder="gender"
              disabled={form.formState.isSubmitting}
              {...form.register("gender")}
            />
            <label className="form-check-label" htmlFor={id + "gender-M"}>
              Male
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              type="radio"
              value="F"
              id={id + "gender-F"}
              className="form-check-input"
              placeholder="gender"
              disabled={form.formState.isSubmitting}
              {...form.register("gender")}
            />
            <label className="form-check-label" htmlFor={id + "gender-F"}>
              Female
            </label>
          </div>
        </div>

        {form.formState.errors.gender?.message &&
          <span className="text-danger">{form.formState.errors.gender.message}</span>
        }
      </div>

      <div>
        <label htmlFor={id + "visaStatus"} className="form-label">Visa status</label>
        <select
          id={id + "visaStatus"}
          className="form-control"
          placeholder="visaStatus"
          disabled={form.formState.isSubmitting}
          {...form.register("visaStatus", {required: Responses.messages.required})}
        >
          <option value="">Select</option>
          {Staff.VALID_VISA_STATUSES.map(x =>
            <option key={x} value={x}>{x}</option>
          )}
        </select>

        {form.formState.errors.visaStatus?.message &&
          <span className="text-danger">{form.formState.errors.visaStatus.message}</span>
        }
      </div>

      <div>
        <label htmlFor={id + "station"} className="form-label">Nearest station</label>
        <input
          id={id + "station"}
          className="form-control"
          placeholder="station"
          disabled={form.formState.isSubmitting}
          {...form.register("station", {required: Responses.messages.required})}
        />

        {form.formState.errors.station?.message &&
          <span className="text-danger">{form.formState.errors.station.message}</span>
        }
      </div>

      <div>
        <label htmlFor={id + "experience"} className="form-label">English Teaching experience</label>
        <select
          id={id + "experience"}
          className="form-control"
          placeholder="experience"
          disabled={form.formState.isSubmitting}
          {...form.register("experience", {required: Responses.messages.required})}
        >
          <option value="">Select</option>
          {Staff.VALID_ENGLISH_TEACHING_EXPERIENCES.map(x =>
            <option key={x} value={x}>{x}</option>)
          }
        </select>

        {form.formState.errors.experience?.message &&
          <span className="text-danger">{form.formState.errors.experience.message}</span>
        }
      </div>

      <div>
        <label htmlFor={id + "introduction"} className="form-label">Introduction</label>
        <textarea
          id={id + "introduction"}
          className="form-control"
          placeholder="introduction"
          rows={6}
          disabled={form.formState.isSubmitting}
          {...form.register("introduction")}
        />

        {form.formState.errors.introduction?.message &&
          <span className="text-danger">{form.formState.errors.introduction.message}</span>
        }
      </div>

      <div>
        <label htmlFor={id + "note"} className="form-label">Note</label>
        <textarea
          id={id + "note"}
          className="form-control"
          placeholder="note"
          rows={4}
          disabled={form.formState.isSubmitting}
          {...form.register("note")}
        />

        {form.formState.errors.note?.message &&
          <span className="text-danger">{form.formState.errors.note.message}</span>
        }
      </div>

      <div>
        <label htmlFor={id + "languages0"} className="form-label">Languages</label>

        {languages.fields.map((field, index) => (
          <div key={field.id}>
            <div className="input-group">
              <select
                id={id + "languages" + index}
                className="form-control"
                placeholder="languages"
                disabled={form.formState.isSubmitting}
                {...form.register(`languages.${index}.value`, {
                  required: Responses.messages.requiredNotEmpty
                })}
              >
                {Staff.VALID_LANGUAGES.map(x => (
                  <option key={x} value={x}>{x}</option>
                ))}
              </select>
              <button type="button" className="btn btn-danger" onClick={() => languages.remove(index)}>
                Remove
              </button>
            </div>

            {form.formState.errors.languages && form.formState.errors.languages[index]?.value?.message &&
              <div className="text-danger">{form.formState.errors.languages[index]?.value?.message}</div>
            }
          </div>
        ))}

        <button type="button" className="form-control btn btn-primary mt-2" onClick={() => languages.append({value: ''})}>
          Add Language
        </button>
      </div>

      <div>
        <label htmlFor={id + "roomURL"} className="form-label">Room URL</label>
        <input
          id={id + "roomURL"}
          className="form-control"
          placeholder="roomURL"
          disabled={form.formState.isSubmitting}
          {...form.register("roomURL")}
        />

        {form.formState.errors.roomURL?.message &&
          <span className="text-danger">{form.formState.errors.roomURL.message}</span>
        }
      </div>

      <div>
        <label htmlFor={id + "slots0"} className="form-label">Slots</label>

        {slots.fields.map((field, index) => (
          <div key={field.id}>
            <div className="input-group">
              <select
                id={id + "slots" + index}
                className="form-control"
                placeholder="slots"
                disabled={form.formState.isSubmitting}
                {...form.register(`slots.${index}.day`, {
                  valueAsNumber: true,
                  required: Responses.messages.requiredNotEmpty
                })}
              >
                {Staff.VALID_DAYS.map(x => (
                  <option key={x} value={x}>{Staff.getDayOfWeek(x)}</option>
                ))}
              </select>

              <input
                type="time"
                id={id + "slots" + index}
                className="form-control"
                placeholder="slots"
                disabled={form.formState.isSubmitting}
                {...form.register(`slots.${index}.time`, {
                  required: Responses.messages.requiredNotEmpty
                })}
              />

              <button type="button" className="btn btn-danger" onClick={() => slots.remove(index)}>
                Remove
              </button>
            </div>

            {form.formState.errors.slots && form.formState.errors.slots[index]?.day?.message &&
              <div className="text-danger">{form.formState.errors.slots[index]?.day?.message}</div>
            }

            {form.formState.errors.slots && form.formState.errors.slots[index]?.time?.message &&
              <div className="text-danger">{form.formState.errors.slots[index]?.time?.message}</div>
            }
          </div>
        ))}

        <button type="button" className="form-control btn btn-primary mt-2" onClick={() => slots.append({day: 0, time: ""})}>
          Add slot
        </button>
      </div>

      <hr />

      <button className="btn btn-primary">
        {form.formState.isSubmitting
          ? <Spinner magnification={1}/>
          : props.previous === null ? <>Add staff</> : <>Update</>
        }
      </button>

      {form.formState.errors.root?.message &&
        <div className="text-danger">{form.formState.errors.root.message}</div>
      }
    </form>
  </>
}
