export const publicConfigCommon = {
  serviceName: "LeafCup",
  title: "LeafCup",
  region: "asia-northeast1",

  service: {
    topURL: "https://leafcup.com/",
  },

  chat: {
    cancelFee: 200,
    price: 1540,
    minutes: 50,
  },
}
