import {FirebaseAuthAccess} from "./access/firebase-auth-access";
import {ServiceApiAccess} from "./access/service-api-access";
import {FirebaseStorageAccess} from "./access/firebase-storage-access";

class Core {
  public readonly auth = new FirebaseAuthAccess()
  public readonly api = new ServiceApiAccess(this.auth);
  public readonly storage = new FirebaseStorageAccess(this.auth);
}

export const core = new Core()
