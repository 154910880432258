import React, {useRef, useState} from "react";
import {useSignUpAccessContext} from "../components/guards/SignUpGuard";
import {NavLink} from "react-router-dom";
import {Paths} from "../lib/paths";
import {Spinner} from "../components/Spinner";
import {core} from "../core";
import {usePromise} from "../util/hook";
import {StripeCardElement, StripeCardElementOptions} from "@stripe/stripe-js"
import {CardElement, Elements} from "@stripe/react-stripe-js";
import {getStripe, stripePromise} from "../access/stripe-access";
import {firstValueFrom} from "rxjs";
import {useForm} from "react-hook-form";
import {Responses} from "../../functions/src/shared/response";
import {User as FirebaseUser} from "@firebase/auth";

type CardForm = {
  name: string,
}

const cardElementOptions: StripeCardElementOptions = {
  style: {
    base: {
      fontSize: "16px",
      color: "#424770",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {color: "#9e2146"},
  },
  hidePostalCode: true,
}

export const MyPaymentMethods = () => {

  const cardRef = useRef<StripeCardElement>()
  const context = useSignUpAccessContext()
  const [reload, setReload] = useState(0);
  const paymentMethods = usePromise(() =>
      firstValueFrom(core.api.listPaymentMethods(context.user.value.firebase)),
    [reload],
  )
  const form = useForm<CardForm>({
    mode: "onBlur",
  });

  async function submit(value: CardForm) {
    const card = cardRef.current
    if (!card) {
      console.error("card is not found.", cardRef)
      return
    }
    const stripe = await getStripe()
    const {paymentMethod} = await stripe.createPaymentMethod({
      type: "card",
      card,
      billing_details: {name: value.name},
    })
    if (!paymentMethod) {
      form.setError("root", {message: "お支払い方法の登録に失敗しました。カード番号や有効期限、セキュリティコードが正しいかどうかをご確認ください。"})
      console.error("paymentMethod is not found.", paymentMethod)
      return;
    }
    await firstValueFrom(core.api.appendPaymentMethod(context.user.value.firebase, {
      paymentMethodId: paymentMethod.id,
      asDefault: false
    })).then(() => {
      setReload(new Date().getTime());
      form.reset();
      cardRef.current?.clear();
    })
  }

  return <>
    <div className="row">
      <div className="col-12 text-center">
        <h1 className="page-title">お支払い方法</h1>
      </div>
    </div>

    <div className="row mt-5">
      <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 d-flex flex-column gap-5 pt-3">
        <div className="text-center pt-3">
          <NavLink to={Paths.my.path}>マイページに戻る</NavLink>
        </div>

        <div className="text-center">
          <h3 className="page-section">お支払い方法一覧</h3>
        </div>

        {paymentMethods === "loading" ? (
          <div className="text-center pt-3">
            <Spinner magnification={3}/>
          </div>
        ) : paymentMethods === "error" ? (
          <div className="text-center pt-3">
            <div className="text-danger">
              {Responses.messages.systemError}
            </div>
          </div>
        ) : (
          <table className="table table-sm">
            <thead className="thead-dark">
            <tr>
              <th>名前</th>
              <th>種別</th>
              <th></th>
            </tr>
            </thead>
            <tbody className="table-group-divider">
            {paymentMethods.value.length === 0 && (
              <tr>
                <td colSpan={3}>
                  お支払い方法がまだ登録されていません。
                </td>
              </tr>
            )}
            {paymentMethods.value.map(e =>
              <tr key={e.id}>
                <td className="align-middle">
                  {e.name}
                </td>
                <td className="align-middle">
                  カード (<code>*** {e.last4}</code>)
                </td>
                <td className="align-middle text-end">
                  <DeletePaymentMethodButton id={e.id} firebaseUser={context.user.value.firebase} setReload={setReload}/>
                </td>
              </tr>
            )}
            </tbody>
          </table>
        )}

        <div className="text-center">
          <h3 className="page-section">お支払い方法の登録</h3>
        </div>

        <form onSubmit={form.handleSubmit(submit)} className="d-flex flex-column gap-3">
          <div>
            <label htmlFor="paymentMethodName" className="form-label">名前</label>

            <input
              type="text"
              className="form-control"
              id="paymentMethodName"
              {...form.register("name", {
                required: Responses.messages.required,
                maxLength: Responses.messages.maxLength(16),
                minLength: Responses.messages.minLength(2),
              })}
            />
            <div className="form-text">このお支払い方法を表す自由な名前を設定します。</div>

            {form.formState.errors.name?.message && (
              <div className="text-danger">{form.formState.errors.name.message}</div>
            )}
          </div>

          <div>
            <label className="form-label">カード情報</label>

            <Elements stripe={stripePromise}>
              <CardElement options={cardElementOptions} onReady={e => cardRef.current = e}/>
            </Elements>

            {form.formState.errors.root?.message && (
              <div className="text-danger">{form.formState.errors.root.message}</div>
            )}
          </div>

          <button className="btn btn-primary" disabled={form.formState.isSubmitting}>
            {form.formState.isSubmitting ? <Spinner magnification={1}/> : "追加"}
          </button>
        </form>

        <div className="text-center pt-3">
          <NavLink to={Paths.my.path}>マイページに戻る</NavLink>
        </div>
      </div>
    </div>
  </>
}

const DeletePaymentMethodButton = (props: { id: string, firebaseUser: FirebaseUser, setReload: (value: number) => void }) => {

  const form = useForm();

  async function deletePaymentMethod() {
    await firstValueFrom(core.api.deletePaymentMethod(
      props.firebaseUser,
      {paymentMethodId: props.id}
    )).catch(e => {
      console.error(e);
      form.setError("root", {})
    })
  }

  if (form.formState.isSubmitSuccessful) {
    props.setReload(new Date().getTime());
  }

  return (
    <form onSubmit={form.handleSubmit(deletePaymentMethod)}>
      <button className="btn btn-danger btn-sm" disabled={form.formState.isSubmitting}>
        {form.formState.isSubmitting ? <Spinner magnification={1}/> : "削除"}
      </button>
    </form>
  )
}
