import {nonNullable} from "./util";

export class Time {

  constructor(
    public readonly hours: number,
    public readonly minutes: number,
    public readonly seconds: number | undefined,
  ) {
    if (this.seconds !== undefined && 60 < this.seconds) {
      this.minutes += Math.floor(this.seconds / 60);
      this.seconds = this.seconds % 60;
    }
    if (60 < this.minutes) {
      this.hours += Math.floor(this.minutes / 60);
      this.minutes = this.minutes % 60;
    }
  }

  static parse(value: string): Time | undefined {
    if (!value) {
      console.warn(`invalid time value: ${value}`);
      return undefined;
    }

    const split = value.split(":");
    if (split.length < 2 || 3 < split.length) {
      console.warn(`invalid time value: ${value}`);
      return undefined;
    }

    const hours = parseInt(split[0], 10);
    const minutes = parseInt(split[1], 10);
    const seconds = split.length === 3 ? parseInt(split[2], 10) : undefined;

    if (isNaN(hours) || isNaN(minutes) || (seconds !== undefined && isNaN(seconds))) {
      console.warn(`invalid time value: ${value}`);
      return undefined;
    }

    return new Time(hours, minutes, seconds);
  }

  toString(): string {
    return [this.hours, this.minutes, this.seconds]
      .filter(nonNullable)
      .map(x => Time.zeroPadding(x, 2))
      .join(":");
  }

  private static zeroPadding(value: number, length: number): string {
    return value.toString().padStart(length, '0');
  }

  addHours(value: number): Time {
    return new Time(this.hours + value, this.minutes, this.seconds);
  }

  addMinutes(value: number): Time {
    return new Time(this.hours, this.minutes + value, this.seconds);
  }

  addSeconds(value: number): Time {
    return new Time(this.hours, this.minutes, (this.seconds ?? 0) + value);
  }
}
