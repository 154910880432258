declare class Id<T extends string> {
  // noinspection JSUnusedGlobalSymbols
  private IDENTITY: T;
}

export type UserId = Id<'User'> & string;

export function UserId(id: string): UserId {
  return id as UserId;
}

export type AdminId = Id<'Admin'> & string;

export function AdminId(id: string): AdminId {
  return id as AdminId;
}

export type StaffId = Id<'Staff'> & string;

export function StaffId(id: string): StaffId {
  return id as StaffId;
}

export type ReservationId = Id<'Reservation'> & string;

export function ReservationId(id: string): ReservationId {
  return id as ReservationId;
}
