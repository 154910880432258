type Callback<T> = {
  (value: T): void;
}

export class Promises {
  static of<T>(value: T): Promise<T> {
    return new Promise((resolve) => resolve(value))
  }

  static error<T>(error: any): Promise<T> {
    return new Promise((_resolve, reject) => reject(error))
  }

  static fromCallback<T>(x: (callback: Callback<T>) => void): Promise<T> {
    return new Promise<T>((resolve) => x(value => resolve(value)))
  }
}
