import React from "react";
import {Spinner} from "../../components/Spinner";
import {useUsers} from "../../access/firestore-access";
import {NavLink} from "react-router-dom";
import {Paths} from "../../lib/paths";
import {publicConfig} from "../../../functions/src/shared/public-config";

export const AdminUsers = () => {

  const limit = publicConfig.env === "local" ? 2 : 50;
  const users = useUsers(limit);

  return <>
    <div className="row">
      <div className="col-12 offset-xl-2 col-xl-8 text-center">
        <h1 className="page-title">User</h1>
      </div>

      <div className="col-12 offset-xl-2 col-xl-8 text-center">
        <table className="table table-striped">
          <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Name</th>
            <th scope="col">Registered at</th>
            <th scope="col">Updated at</th>
            <th scope="col" className="text-center">Action</th>
          </tr>
          </thead>
          <tbody>
          {users.values === "loading"
            ?
            <tr>
              <td scope="row" colSpan={5}>
                <Spinner magnification={1}/>
              </td>
            </tr>
            : users.values.map(user =>
              <tr key={user.id}>
                <td scope="row" className="word-break-break-all">{user.id}</td>
                <td scope="row" className="word-break-break-all">{user.nickname}</td>
                <td scope="row">{new Date(user.createdAt).toLocaleString()}</td>
                <td scope="row">{new Date(user.updatedAt).toLocaleString()}</td>
                <td scope="row" className="text-center">
                  <NavLink to={Paths.admin.users.user.getPath(user.id)}>詳細</NavLink>
                </td>
              </tr>
            )
          }
          {users.next.exists && (
            <tr>
              <td
                scope="row"
                colSpan={5}
                className="text-center bg-light cursor-pointer-opacity"
                onClick={users.next.load}
              >
                {limit}件を追加
              </td>
            </tr>
          )}
          </tbody>
        </table>
      </div>
    </div>
  </>
}
