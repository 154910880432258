import React from "react";
import {User as FirebaseUser} from "@firebase/auth";
import {Dialog} from "./Dialog";
import {UserProfileIconUpload} from "./UserProfileIconUpload";

export const UserProfileIcon = (props: {
  firebaseUser: FirebaseUser | undefined,
  editable: {
    value: true,
    dialog: Dialog,
  } | {
    value: false,
  },
  magnification: 1 | 2 | 3 | 4 | 5,
  className?: string,
}) => {

  let size = 0;
  switch (props.magnification) {
    case 1:
      size = 16;
      break;
    case 2:
      size = 32;
      break;
    case 3:
      size = 45;
      break;
    case 4:
      size = 64;
      break;
    case 5:
      size = 80;
      break;
  }

  const {firebaseUser, editable} = props;

  return (
    <span className={"position-relative " + (props.className ?? "")}>
      {props.firebaseUser?.photoURL ? (
        <img className="rounded-circle" src={props.firebaseUser.photoURL} alt="ユーザーアイコン" style={{width: `${size}px`, height: `${size}px`}}/>
      ) : (
        <i className={`fas fa-circle-user fa-${props.magnification}x fa-fw`}/>
      )}

      {(firebaseUser && editable.value) && (
        <i
          className="text-primary fas fa-pen position-absolute cursor-pointer-opacity"
          style={{top: "0", right: "0"}}
          onClick={() => editable.dialog.setValue({
            show: true,
            closable: true,
            title: "アイコンの設定",
            body: <>
              <UserProfileIconUpload firebaseUser={firebaseUser}/>
            </>,
            footer: null,
          })}
        />
      )}
    </span>
  )
}
