import React from "react";
import {NavLink} from "react-router-dom";
import {Paths} from "../lib/paths";
import {CommonAccessValue} from "./guards/CommonAccessGuard";
import {publicConfig} from "../../functions/src/shared/public-config";
import {UserProfileIcon} from "./UserProfileIcon";
import {LoadingOption} from "../../functions/src/shared/types/loading";

type Navigation = { kind: "link", className?: string, label: string, path: string }
  | { kind: "button", className?: string, label: string, onClick?: () => void }
  | { kind: "user-icon", className?: string, label: string, path: string }

const getNavigation = (user: LoadingOption<CommonAccessValue>): Navigation[] => {

  let navigations: Navigation[] = [];

  if (user === "loading") {
    return navigations;
  }

  if (user === null || user.service === null) {
    navigations = navigations.concat([
      {kind: "link", path: Paths.register.path, label: "Register"},
      {kind: "link", path: Paths.login.path, label: "Sign In"},
    ]);
  } else {
    navigations = navigations.concat([
      {kind: "user-icon", path: Paths.my.path, label: "マイページ"},
    ]);
  }

  return navigations;
}

export const Header = (props: { user: LoadingOption<CommonAccessValue> }) => {

  const navigations = getNavigation(props.user);

  return <>
    <nav className="navbar navbar-expand-sm fixed-top bg-darkred" style={{padding: "0.8rem 10%"}} data-bs-theme="dark">
      <NavLink className="navbar-brand" to={publicConfig.service.topURL}>
        {publicConfig.title}
      </NavLink>

      <div className="flex-grow-1">
        <ul className="d-flex navbar-nav ms-auto justify-content-end">
          {navigations.map(navigation =>
            <li key={navigation.label} className="nav-item d-flex align-items-center justify-content-end">
              {navigation.kind === "link" && (
                <NavLink
                  className={navigation.className ?? "nav-link"}
                  aria-current="page"
                  to={navigation.path}
                >
                  {navigation.label}
                </NavLink>
              )}
              {navigation.kind === "button" && (
                <button
                  className={navigation.className ?? "btn btn-primary"}
                  aria-current="page"
                  onClick={navigation.onClick}
                >
                  {navigation.label}
                </button>
              )}
              {navigation.kind === "user-icon" && (
                <>
                  {props.user === "loading" ? <></> : <>
                    <div className="d-flex align-items-center justify-content-end header-nickname">
                      <NavLink
                        className={navigation.className ?? "nav-link active"}
                        aria-current="page"
                        to={navigation.path}
                      >
                        <UserProfileIcon firebaseUser={props.user?.firebase} editable={{value: false}} magnification={3}/>
                      </NavLink>
                      <NavLink
                        className={navigation.className ?? "nav-link active text-ellipsis"}
                        aria-current="page"
                        to={navigation.path}
                      >
                        <span>{props.user?.service?.nickname}</span>
                      </NavLink>
                    </div>
                  </>}
                </>
              )}
            </li>
          )}
        </ul>
      </div>
    </nav>
  </>
}
