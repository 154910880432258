import React from "react";
import {useAdminAccessContext} from "../../components/guards/AdminGuard";

export const AdminTop = () => {

  const context = useAdminAccessContext()

  return <>
    <div className="row">
      <div className="col-12 offset-xl-2 col-xl-8 text-center">
        <h1 className="page-title">Admin tool</h1>
      </div>

      <div className="col-12 offset-xl-2 col-xl-8 text-center">
        <span>Hello, {context.user.value.service.nickname}!</span>
      </div>
    </div>
  </>
}
