import React, {useEffect} from "react";
import {core} from "../core";
import {User as FirebaseUser} from "@firebase/auth";
import {FirebaseAuthProvider, Providers} from "../access/firebase-auth-access";
import {from} from "rxjs";
import {useForm} from "react-hook-form";
import {Spinner} from "./Spinner";

export const LoginLinkButton = (props: {
  firebaseUser: FirebaseUser,
  provider: FirebaseAuthProvider,
  providers: Providers,
}) => {

  const value = props.providers.find(props.provider);
  const form = useForm();

  const onLink = () => {
    return core.auth.linkWithRedirect(props.firebaseUser, props.provider);
  }

  const onUnlink = () => {
    if (props.providers.length <= 1) {
      return;
    }
    if (!confirm(`${props.provider}アカウントとの連携を解除します。以降、このアカウントでのログインができなくなります。本当によろしいですか？`)) {
      return;
    }
    return core.auth.unlink(props.firebaseUser, props.provider).then(() =>
      window.location.reload()
    );
  }

  useEffect(() => {
    const subscription = from(core.auth.getRedirectResult().then(cred => {
      if (cred === null || cred.user.email === null || cred.providerId == null) {
        return;
      }
      if (Providers.isNotMatch(cred.providerId, props.provider)) {
        return;
      }
      return core.auth.updateEmail(props.firebaseUser, cred.user.email)
    }).catch(e => {
      const cred = Providers.credentialFromError(props.provider, e);
      if (cred !== null && Providers.isNotMatch(cred.providerId, props.provider)) {
        return;
      }
      if (e.code === "auth/credential-already-in-use") {
        alert(`この${props.provider}アカウントは既に別のアカウントと紐づけられています。`);
        return;
      }
      console.error(e);
    })).subscribe()
    return () => subscription.unsubscribe();
  }, [props.firebaseUser, props.provider]);

  return value === undefined
    ? (
      <form onSubmit={form.handleSubmit(onLink)}>
        <button className="btn btn-primary" disabled={form.formState.isSubmitting}>
          {form.formState.isSubmitting ? <Spinner magnification={1}/> : "連携"}
        </button>
      </form>
    )
    : (
      <form onSubmit={form.handleSubmit(onUnlink)}>
        <button className="btn btn-danger" disabled={props.providers.length <= 1 || form.formState.isSubmitting}>
          {form.formState.isSubmitting ? <Spinner magnification={1}/> : "連携解除"}
        </button>
        {props.providers.length <= 1 && (
          <div className="text-muted font-small">少なくとも1つのログイン方法が必要です。</div>
        )}
      </form>
    )
}
