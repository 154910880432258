import React from "react";
import {Spinner} from "../../components/Spinner";
import {useStaff} from "../../access/firestore-access";
import {Paths} from "../../lib/paths";
import {useForm} from "react-hook-form";
import {core} from "../../core";
import {useAdminAccessContext} from "../../components/guards/AdminGuard";
import {firstValueFrom} from "rxjs";
import {Navigate} from "react-router-dom";
import {toMap} from "../../../functions/src/shared/lib/util";
import {Responses} from "../../../functions/src/shared/response";
import {StaffForm} from "../../components/form/StaffForm";

export const AdminStaffsDetail = () => {

  const context = useAdminAccessContext();
  const targetStaffId = Paths.admin.staffs.staff.usePath().staffId;
  const staff = useStaff(targetStaffId);
  const formDelete = useForm();

  const onDelete = () => {
    if (!confirm("このスタッフを削除します。本当によろしいですか？")) {
      formDelete.setError("root", {});
      return;
    }
    return firstValueFrom(
      core.api.updateStaff(context.user.value.firebase, {operation: "REMOVE", staffId: targetStaffId})
    ).then(response => {
      if (response.status === 200) {
        return;
      }
      if (response.status === 400) {
        for (const [key, value] of toMap(response.value.errors)) {
          if (key === "operation") {
            formDelete.setError("root", {message: Responses.messages.systemError})
          } else {
            formDelete.setError(key, {message: Responses.messages.convert(value)})
          }
        }
      } else if (400 < response.status) {
        formDelete.setError("root", {message: response.value.message})
      } else {
        console.error(response)
        formDelete.setError("root", {message: Responses.messages.systemError});
      }
    })
  }

  if (formDelete.formState.isSubmitSuccessful) {
    return <Navigate to={Paths.admin.staffs.path}/>
  }

  return <>
    <div className="row">
      <div className="col-12 offset-xl-2 col-xl-8 text-center">
        <h1 className="page-title">Staff</h1>
      </div>

      <div className="col-12 offset-xl-2 col-xl-8 text-center d-flex flex-column gap-3 pt-5">
        {staff === "loading" ? <Spinner magnification={3}/> : staff === null ? "ユーザーが見つかりませんでした。" : (
          <>
            <StaffForm firebaseUser={context.user.value.firebase} previous={staff} />

            <form onSubmit={formDelete.handleSubmit(onDelete)}>
              <button className="form-control btn btn-danger" disabled={formDelete.formState.isSubmitting}>
                {formDelete.formState.isSubmitting ? <Spinner magnification={1}/> : <>Delete</>}
              </button>
            </form>
          </>
        )}
      </div>
    </div>
  </>
}
