import React from "react";
import * as Rx from "rxjs";
import {useForm} from "react-hook-form";
import {core} from "../../core";
import {User as FirebaseUser} from "@firebase/auth";
import {AdminId} from "../../../functions/src/shared/entity/identity";
import {toMap} from "../../../functions/src/shared/lib/util";
import {Responses} from "../../../functions/src/shared/response";
import {Admin} from "../../../functions/src/shared/entity/admin";
import {CollectionValue} from "../../access/firestore-access";
import {Spinner} from "../Spinner";

type EmailForm = {
  adminId: string,
}

export const AdminForm = (props: { firebaseUser: FirebaseUser, admins: CollectionValue<Admin> }) => {

  const form = useForm<EmailForm>({mode: "onBlur"})

  const onSubmit = (value: EmailForm) => {
    return Rx.firstValueFrom(core.api.addAdmin(props.firebaseUser, AdminId(value.adminId)))
      .then(response => {
        if (response.status === 200) {
          props.admins.reload()
        } else if (response.status === 400) {
          for (const [key, val] of toMap(response.value.errors)) {
            if (key === "operation") {
              form.setError("root", {message: Responses.messages.systemError})
            } else {
              form.setError(key, {message: Responses.messages.convert(val)})
            }
          }
        } else if (400 < response.status) {
          form.setError("root", {message: response.value.message})
        } else {
          console.error(response)
          form.setError("root", {message: Responses.messages.systemError});
        }
      }).catch(e => {
        console.error(e)
        form.setError("root", {message: Responses.messages.systemError});
      });
  }

  return <>
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <div className="input-group">
        <input
          className="form-control"
          placeholder="example@example.com"
          type="text"
          disabled={form.formState.isSubmitting}
          {...form.register("adminId", {required: Responses.messages.required})}
        />
        <button className="btn btn-primary">
          {form.formState.isSubmitting ? <Spinner magnification={1}/> : <>管理者を追加</>}
        </button>
      </div>

      {form.formState.errors.adminId?.message &&
        <span className="text-danger">{form.formState.errors.adminId.message}</span>
      }
      {form.formState.errors.root?.message &&
        <span className="text-danger">{form.formState.errors.root.message}</span>
      }
    </form>
  </>
}
