import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {core} from "../core";
import {User as FirebaseUser} from "@firebase/auth";
import {Spinner} from "./Spinner";

export const EmailVerifyAlert = (props: { firebaseUser: FirebaseUser }) => {

  const form = useForm();
  const [completed, setCompleted] = useState<boolean>(false);
  const onSubmit = () => {
    return core.auth.sendEmailVerification(props.firebaseUser)
      .then(() => setCompleted(true))
      .catch(e => {
        if (e.code === "auth/too-many-requests") {
          form.setError("root", {message: "メールは既に送信済みです。メールが届かない場合、メールアドレスが正しいかどうか、またスパムフォルダに入っていないかご確認下さい。"});
          return;
        }
        console.error(e);
      })
  }

  if (props.firebaseUser.emailVerified) {
    return <></>
  }

  return (
    <div className="alert alert-warning mt-2 mb-0" role="alert">
      メールアドレスが未確認です。メールボックスをご確認頂き、メール内のURLをクリックして下さい。

      <form onSubmit={form.handleSubmit(onSubmit)} className="text-end">
        <button className="btn btn-outline-primary" disabled={form.formState.isSubmitting || completed || !form.formState.isValid}>
          {form.formState.isSubmitting ? <Spinner magnification={1}/> : "メールを再送"}
        </button>
      </form>

      {completed && (
        <div className="text-success small">メールを再送しました。メールボックスをご確認下さい。</div>
      )}
      {form.formState.errors.root?.message &&
        <div className="text-danger small">{form.formState.errors.root.message}</div>
      }
    </div>
  )
}
