import React from "react";
import {useSignUpAccessContext} from "../components/guards/SignUpGuard";
import {Providers} from "../access/firebase-auth-access";
import {LoginEmailUpdate} from "../components/LoginEmailUpdate";
import {Navigate} from "react-router-dom";
import {Paths} from "../lib/paths";

export const AccountEmailUpdate = () => {

  const context = useSignUpAccessContext();
  const providers = new Providers(context.user.value.firebase.providerData)

  if (providers.password === undefined) {
    return <Navigate to={Paths.my.path}/>
  }

  return <>
    <div className="row">
      <div className="col-12 text-center">
        <h1 className="page-title">メールアドレスの変更</h1>
      </div>
    </div>

    <div className="row mt-5">
      <div className="col-12 offset-sm-2 col-sm-8 col-lg-4 offset-lg-4 pt-3">
        <LoginEmailUpdate user={context.user.value} previous={providers.password}/>
      </div>
    </div>
  </>
}
