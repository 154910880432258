import React, {useState} from "react";
import {Modal} from "react-bootstrap";

export type Dialog = {
  element: React.JSX.Element,
  show: () => void,
  hide: () => void,
  setValue: (value: DialogValue) => void,
}

export type DialogValue = {
  show: boolean,
  title: string,
  body: React.JSX.Element,
  footer: React.JSX.Element | null,
  closable: boolean,
}

export const useDialog = (): Dialog => {
  const [value, setValue] = useState<DialogValue>({
    show: false,
    title: "",
    body: <></>,
    footer: null,
    closable: true,
  });

  const show = () => setValue({...value, show: true});
  const hide = () => setValue({...value, show: false});

  const element = (
    <>
      <Modal show={value.show} animation={true} onHide={value.closable ? hide : () => null}>
        <Modal.Header closeButton={value.closable}>
          <Modal.Title>{value.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {value.body}
        </Modal.Body>
        {value.footer && (
          <Modal.Footer>
            {value.footer}
          </Modal.Footer>
        )}
      </Modal>
    </>
  );

  return {
    element,
    show,
    hide,
    setValue,
  }
}


