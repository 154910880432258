import {useForm} from "react-hook-form";
import {User, UserMeta} from "../../../functions/src/shared/entity/user";
import * as Rx from "rxjs";
import {core} from "../../core";
import React, {useId} from "react";
import {User as FirebaseUser} from "@firebase/auth";
import {Spinner} from "../Spinner";
import {Responses} from "../../../functions/src/shared/response";
import {toMap} from "../../../functions/src/shared/lib/util";

export const UserProfileForm = (
  props: {
    user: { service: User | null, firebase: FirebaseUser },
    onSuccess: (user: User) => void
  }
) => {

  const id = useId()
  const isRegister = props.user.service === null;
  const formUpdate = useForm<UserMeta>({mode: "onBlur"});
  const formCancel = useForm({mode: "onBlur"});

  const isSubmitting = formUpdate.formState.isSubmitting || formCancel.formState.isSubmitting

  const onSubmit = (value: UserMeta) => {
    // メール確認処理の中でユーザーの displayName が使われるため、最初に Firebase Auth のプロフィールをアップデートする
    return core.auth.updateDisplayName(props.user.firebase, value.nickname).then(() =>
      Rx.firstValueFrom(core.api.updateUser(
        props.user.firebase, {operation: isRegister ? "REGISTER" : "MODIFY", ...value}
      ))
        .then(response => {
          if (response.status !== 200) {
            return response
          }
          if (props.user.service !== null || props.user.firebase.emailVerified) {
            return response
          } else {
            return core.auth.sendEmailVerification(props.user.firebase).then(() => response)
          }
        })
        .then(response => {
          if (response.status === 200) {
            props.onSuccess(response.value);
          } else if (response.status === 400) {
            for (const [key, value] of toMap(response.value.errors)) {
              if (key === "operation") {
                formUpdate.setError("root", {message: Responses.messages.systemError})
              } else {
                formUpdate.setError(key, {message: Responses.messages.convert(value)})
              }
            }
          } else if (400 < response.status || response.status < 500) {
            formUpdate.setError("root", {message: response.value.message});
          } else {
            console.error(response);
            formUpdate.setError("root", {message: Responses.messages.systemError});
          }
        })
        .catch(e => {
          console.error(e);
          formUpdate.setError("root", {message: Responses.messages.systemError});
        })
    )
  }

  const onCancel = () => {
    return Rx.firstValueFrom(core.api.updateUser(props.user.firebase, {operation: "UNREGISTER"}))
      .then(() => {
        window.location.reload();
      }).catch(e => {
        console.error(e);
        formCancel.setError("root", {message: Responses.messages.systemError});
      })
  }

  return <>
    <form className="d-flex flex-column gap-3" onSubmit={formUpdate.handleSubmit(onSubmit)}>
      <div>
        <div className="form-floating">
          <input
            id={id + "name"}
            className="form-control"
            placeholder="例）山田太郎"
            defaultValue={(isRegister ? props.user.firebase.displayName : props.user.service?.name) ?? undefined}
            disabled={isSubmitting}
            {...formUpdate.register("name", {
              required: Responses.messages.required,
            })}
          />
          <label htmlFor={id + "name"}>お名前</label>
        </div>

        {formUpdate.formState.errors.name?.message &&
          <div className="text-danger">{formUpdate.formState.errors.name.message}</div>
        }
      </div>

      <div>
        <div className="form-floating">
          <input
            id={id + "nameKana"}
            className="form-control"
            placeholder="例）ヤマダタロウ"
            defaultValue={(isRegister ? undefined : props.user.service?.nameKana)}
            disabled={isSubmitting}
            {...formUpdate.register("nameKana", {
              required: Responses.messages.required,
              validate: {
                katakana: Responses.messages.katakana,
              },
            })}
          />
          <label htmlFor={id + "nameKana"}>お名前 (カタカナ)</label>
        </div>

        {formUpdate.formState.errors.nameKana?.message &&
          <div className="text-danger">{formUpdate.formState.errors.nameKana.message}</div>
        }
      </div>

      <div>
        <div className="form-floating">
          <input
            id={id + "nickname"}
            className="form-control"
            placeholder="例）Taro"
            defaultValue={(isRegister ? props.user.firebase.displayName : props.user.service?.nickname) ?? undefined}
            disabled={isSubmitting}
            {...formUpdate.register("nickname", {
              required: Responses.messages.required,
              minLength: Responses.messages.minLength(User.NICKNAME_MIN_LENGTH),
              maxLength: Responses.messages.maxLength(User.NICKNAME_MAX_LENGTH),
              validate: {
                ascii: Responses.messages.ascii,
              }
            })}
          />
          <label htmlFor={id + "nickname"}>ニックネーム</label>
        </div>

        <small className="text-muted ps-2">
          ※ チャットホストスタッフに伝えたい名前をアルファベット8文字以内でご入力ください。
        </small>

        {formUpdate.formState.errors.nickname?.message &&
          <div className="text-danger">{formUpdate.formState.errors.nickname.message}</div>
        }
      </div>

      <div>
        <div className="row">
          <div className="col">
            <label htmlFor={id + "gender"} className="form-label">性別</label>
          </div>
          <div className="col">
            <div className="form-check form-check-inline">
              <input
                type="radio"
                value="M"
                id={id + "gender-M"}
                className="form-check-input"
                defaultChecked={isRegister ? true : props.user.service?.gender === "M"}
                disabled={formUpdate.formState.isSubmitting}
                {...formUpdate.register("gender")}
              />
              <label className="form-check-label" htmlFor={id + "gender-M"}>
                男
              </label>
            </div>
          </div>
          <div className="col">
            <div className="form-check form-check-inline">
              <input
                type="radio"
                value="F"
                id={id + "gender-F"}
                className="form-check-input"
                defaultChecked={isRegister ? false : props.user.service?.gender === "F"}
                disabled={formUpdate.formState.isSubmitting}
                {...formUpdate.register("gender")}
              />
              <label className="form-check-label" htmlFor={id + "gender-F"}>
                女
              </label>
            </div>
          </div>
        </div>

        {formUpdate.formState.errors.gender?.message &&
          <span className="text-danger">{formUpdate.formState.errors.gender.message}</span>
        }
      </div>

      <div>
        <div className="form-floating">
          <input
            id={id + "contactEmail"}
            className="form-control"
            placeholder="例）Taro"
            defaultValue={(isRegister ? props.user.firebase.email : props.user.service?.contactEmail) ?? undefined}
            disabled={isSubmitting}
            {...formUpdate.register("contactEmail", {
              required: Responses.messages.required,
            })}
          />
          <label htmlFor={id + "contactEmail"}>メールアドレス</label>
        </div>

        {formUpdate.formState.errors.contactEmail?.message &&
          <div className="text-danger">{formUpdate.formState.errors.contactEmail.message}</div>
        }
      </div>

      <div>
        <div className="form-floating">
          <input
            id={id + "phone"}
            className="form-control"
            placeholder="例）000-0000-0000"
            defaultValue={(isRegister ? props.user.firebase.phoneNumber : props.user.service?.phone) ?? undefined}
            disabled={isSubmitting}
            {...formUpdate.register("phone", {
              required: Responses.messages.required,
            })}
          />
          <label htmlFor={id + "phone"}>電話番号</label>
        </div>

        {formUpdate.formState.errors.phone?.message &&
          <div className="text-danger">{formUpdate.formState.errors.phone.message}</div>
        }
      </div>

      <div>
        <div className="form-floating">
          <select
            id={id + "prefecture"}
            className="form-select"
            disabled={isSubmitting}
            defaultValue={(isRegister ? undefined : props.user.service?.prefecture)}
            {...formUpdate.register("prefecture", {
              required: Responses.messages.required,
            })}
          >
            <option value={""}>--選択してください--</option>
            {User.VALID_PREFECTURES.map(x =>
              <option key={"prefecture_" + x} value={x}>{x}</option>
            )}
          </select>
          <label htmlFor={id + "prefecture"}>お住まい</label>
        </div>

        {formUpdate.formState.errors.prefecture?.message &&
          <div className="text-danger">{formUpdate.formState.errors.prefecture.message}</div>
        }
      </div>

      <div>
        <div className="form-floating">
          <select
            id={id + "occupation"}
            className="form-select"
            disabled={isSubmitting}
            defaultValue={(isRegister ? undefined : props.user.service?.occupation)}
            {...formUpdate.register("occupation", {
              required: Responses.messages.required,
            })}
          >
            <option value={""}>--選択してください--</option>
            {User.VALID_OCCUPATIONS.map(x =>
              <option key={"occupation_" + x} value={x}>{x}</option>
            )}
          </select>
          <label htmlFor={id + "occupation"}>ご職業</label>
        </div>

        {formUpdate.formState.errors.occupation?.message &&
          <div className="text-danger">{formUpdate.formState.errors.occupation.message}</div>
        }
      </div>

      <div>
        <div className="form-floating">
          <select
            id={id + "how"}
            className="form-select"
            disabled={isSubmitting}
            defaultValue={(isRegister ? undefined : props.user.service?.how)}
            {...formUpdate.register("how", {
              required: Responses.messages.required,
            })}
          >
            <option value={""}>--選択してください--</option>
            {User.VALID_HOWS.map(x =>
              <option key={"how_" + x} value={x}>{x}</option>
            )}
          </select>
          <label htmlFor={id + "how"}>どこでリーフカップをお知りになりましたか？</label>
        </div>

        {formUpdate.formState.errors.how?.message &&
          <div className="text-danger">{formUpdate.formState.errors.how.message}</div>
        }
      </div>

      <button
        className="form-control btn btn-primary"
        disabled={isSubmitting || !formUpdate.formState.isValid}
      >
        {isSubmitting
          ? <Spinner magnification={1}/>
          : props.user.service !== null
            ? <>更新</>
            : <>登録</>
        }
      </button>

      {formUpdate.formState.errors.root?.message &&
        <div className="text-danger">{formUpdate.formState.errors.root.message}</div>
      }
    </form>

    {isRegister && (
      <form onSubmit={formCancel.handleSubmit(onCancel)} className="text-center">
        <button
          className="form-control btn btn-outline-primary btn-sm"
          onClick={formCancel.handleSubmit(onCancel)}
          disabled={isSubmitting}
        >
          {isSubmitting
            ? <Spinner magnification={1}/>
            : "会員登録をキャンセル"
          }
        </button>

        {formCancel.formState.errors.root?.message &&
          <div className="text-danger">{formCancel.formState.errors.root.message}</div>
        }
      </form>
    )}
  </>
}
