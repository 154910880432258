import React, {useState} from "react";
import * as Rx from "rxjs";
import {useSignUpAccessContext} from "../components/guards/SignUpGuard";
import {Paths} from "../lib/paths";
import {useForm} from "react-hook-form";
import {Spinner} from "../components/Spinner";
import {core} from "../core";
import {Responses} from "../../functions/src/shared/response";
import {NavLink} from "react-router-dom";

export const AccountUnregister = () => {

  const context = useSignUpAccessContext();
  const form = useForm();
  const [completed, setCompleted] = useState<boolean>(false);

  const onSubmit = () => {
    if (!confirm("アカウントの削除を実行します。本当によろしいですか？")) {
      return;
    }
    return Rx.firstValueFrom(core.api.updateUser(context.user.value.firebase, {operation: "UNREGISTER"}))
      .then(response => {
        if (response.status === 200) {
          setTimeout(() => {
            window.location.href = Paths.top.path;
          }, 2500);
          setCompleted(true);
        } else {
          form.setError("root", {message: Responses.messages.systemError})
        }
      })
      .catch(e => {
        console.error(e);
        form.setError("root", {message: Responses.messages.systemError})
      })
  }

  return <>
    <div className="row">
      <div className="col-12 mt-3">
        <NavLink to={Paths.top.path}>&lt;&lt;予約ページに戻る</NavLink>
      </div>
      <div className="col-12 text-center">
        <h1 className="page-title">アカウントの削除</h1>
      </div>
    </div>

    <div className="row mt-5">
      <div className="col-12 offset-sm-2 col-sm-8 col-lg-4 offset-lg-4 pt-3">

      {completed ? (
          <div className="text-success">
            サービスをご利用頂き、誠にありがとうございました。<br/>
            アカウントの削除が完了しました。
          </div>
        ) : (
          <>
            <form onSubmit={form.handleSubmit(onSubmit)} className="d-flex flex-column gap-3">
              <h4>削除の確認</h4>
              <span>
                アカウントの削除を実行すると、ユーザー情報や過去の決済履歴など、すべての情報が削除されます。<br/>
                削除されたアカウントは元に戻せませんので、ご了承下さい。
              </span>

              <button className="form-control btn btn-danger" disabled={form.formState.isSubmitting}>
                {form.formState.isSubmitting ? <Spinner magnification={1}/> : "アカウントを削除"}
              </button>

              {form.formState.errors.root?.message &&
                <div className="text-danger">{form.formState.errors.root.message}</div>
              }
            </form>
          </>
        )}
      </div>
    </div>
  </>
}
