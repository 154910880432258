import React, {ReactElement, useEffect, useState} from "react";
import {Outlet, useLocation, useOutletContext} from "react-router-dom";
import {asContextProperty, ContextProperty} from "../../lib/context-property";
import {UserId} from "../../../functions/src/shared/entity/identity";
import {User} from "../../../functions/src/shared/entity/user";
import {Header} from "../Header";
import {User as FirebaseUser} from "@firebase/auth";
import {useCurrentFirebaseUser} from "../../access/firebase-auth-access";
import {FirestoreAccess} from "../../access/firestore-access";
import {Dialog, useDialog} from "../Dialog";
import {LoadingOption} from "../../../functions/src/shared/types/loading";
import {Paths} from "../../lib/paths";
import {publicConfig} from "../../../functions/src/shared/public-config";
import {Footer} from "../Footer";

export type CommonAccessValue = { service: User | null, firebase: FirebaseUser }

export type CommonAccessContext = {
  user: ContextProperty<LoadingOption<CommonAccessValue>>,
  dialog: Dialog,
}

export function useCommonAccessContext() {
  return useOutletContext<CommonAccessContext>()
}

export function CommonAccessGuard(): ReactElement {
  const context: CommonAccessContext = {
    user: asContextProperty(useState<LoadingOption<CommonAccessValue>>("loading")),
    dialog: useDialog(),
  }
  const firebase = useCurrentFirebaseUser();
  const location = useLocation();

  useEffect(() => {
    if (context.user.value !== "loading") {
      return;
    }
    if (firebase === "loading") {
      return;
    }
    if (firebase === null) {
      context.user.set(null);
      return;
    }
    FirestoreAccess.getUser(UserId(firebase.uid)).then(service => {
      context.user.set({firebase: firebase, service})
    })
  }, [context.user, firebase]);

  useEffect(() => {
    Paths.titles.forEach((value, key) => {
      if (new RegExp(`^${key}$`).test(location.pathname)) {
        document.title = `${value} - ${publicConfig.serviceName}`;
        return;
      }
    })
  }, [location.pathname])

  return <>
    <div className="min-vh-100 d-flex flex-column">
      <Header user={context.user.value}/>
      <div className="container-fluid flex-grow-1 mb-5">
        <Outlet context={context}/>
      </div>
      <Footer />
    </div>

    {context.dialog.element}
  </>
}
