import React, {CSSProperties, useState} from "react";
import {createSearchParams, NavLink, useNavigate} from "react-router-dom";
import {Paths} from "../lib/paths";
import {useReservationsByDate, useStaffs} from "../access/firestore-access";
import {Spinner} from "../components/Spinner";
import {Card, CardBody, CardFooter, CardImg, CardTitle} from "react-bootstrap";
import {Staff} from "../../functions/src/shared/entity/staff";
import {publicConfig} from "../../functions/src/shared/public-config";
import {Reservation} from "../../functions/src/shared/entity/reservation";

const reserveButtonStyle: CSSProperties = {
  fontFamily: `nunito,roboto,proxima-nova,"proxima nova",sans-serif`,
  fontSize: "16px",
  lineHeight: "16px",
  fontWeight: "bold",
  padding: "12px 14px",
  boxShadow: "rgba(0, 0, 0, 0.1) 1px 2px 4px",
}

function createScheduleDates(now: Date, count: number): Date[] {
  const start = new Date(now)
  start.setTime(start.getTime() + start.getTimezoneOffset() * 60 * 1000 + 9 * 60 * 60 * 1000);

  // 当日分は出さない = 翌日から表示
  start.setDate(start.getDate() + 1)
  // 22時を過ぎたら更に翌日を出す
  if (22 <= start.getHours()) {
    start.setDate(start.getDate() + 1)
  }
  start.setSeconds(0, 0)
  start.setMinutes(0)
  start.setHours(0)
  const dates: Date[] = []
  for (let i = 0; i < count; ++i) {
    dates.push(new Date(start))
    start.setDate(start.getDate() + 1)
  }
  return dates
}

export const Top = () => {

  const staffs = useStaffs();
  const navigate = useNavigate();

  const today = new Date();
  const dates = createScheduleDates(today, 6)

  const reservations = useReservationsByDate(dates);
  const query = new URLSearchParams(window.location.search)
  const selected = query.get("selected");
  const [selectedDate, setSelectedDate] = useState<Date>(selected ? new Date(parseInt(selected, 10)) ?? dates[0] : dates[0]);

  return <div className="g-0 py-2 px-3">
    <div className="py-3">
      <img className="w-100 d-block d-md-none" src="/top-mobile.jpg" alt="top banner"/>
      <img className="w-100 d-none d-md-block" src="/top.jpg" alt="top banner"/>
    </div>

    <div className="d-flex flex-column mt-3">
      いろんな国の人達とプライベートでお話しませんか？
      Google Meetを利用してご自宅よりリーフカップのチャットホストと５０分のトークをお楽しみいただけます。
      以下ご希望の日付を選択、お話したいスタッフの時間をクリックいただきしてご予約画面にお進みくださいませ。
      初めてご利用の方は予約画面下部のRegisterより会員登録後にご予約が可能となります。
    </div>

    <div className="d-flex flex-column mt-5 mb-4">
      <div className="d-flex gap-2 flex-wrap">
        {dates.map(date => (
          <button
            key={date.getTime()}
            className="btn btn-secondary btn-sm"
            style={reserveButtonStyle}
            disabled={date.getDay() === selectedDate.getDay()}
            onClick={() => {
              navigate({
                pathname: Paths.top.path,
                search: createSearchParams({selected: date.getTime().toString()}).toString()
              }, {replace: true});
              setSelectedDate(date)
            }}
          >
            {Staff.getDateLabel(date)}
          </button>
        ))}
      </div>
    </div>

    {(staffs.values === "loading" || reservations.values === "loading") ? (
      <div className="row">
        <div className="col-12 text-center"><Spinner magnification={3}/></div>
      </div>
    ) : (
      <StaffSlotList
        staffs={staffs.values}
        reservations={reservations.values
          .filter(x => x.date === Reservation.toDateString(selectedDate))
          .filter(x => !x.cancelled)}
        date={selectedDate}
      />
    )}
  </div>
}

const StaffSlotList = (props: { staffs: Staff[], reservations: Reservation[], date: Date }) => {
  const {staffs, reservations, date} = props;
  const enableStaffs = staffs.filter(x =>
    x.slots
    && x.slots.length !== 0
    && x.slots.findIndex(x => x.day === date.getDay()) !== -1
  )

  if (enableStaffs.length === 0) {
    return <>
      <div className="row">
        <div className="col text-center">この曜日の枠はありません。</div>
      </div>
    </>
  }

  return <>
    <div className="row row-cols-1 row-cols-md-3 row-cols-xl-4 g-4">
      {enableStaffs.map(staff => (
        <div key={staff.id} className="col">
          <StaffCard
            staff={staff}
            reservations={reservations.filter(x => x.staffId === staff.id)}
            date={date}
          />
        </div>
      ))}
    </div>
  </>
}

const StaffCard = (props: { staff: Staff, reservations: Reservation[], date: Date }) => {
  const {staff, date} = props;
  const slots = staff.slots?.filter(x => x.day === date.getDay())
  if (slots === undefined) {
    return <></>
  }

  return (
    <Card>
      {staff.photoURL && (
        <CardImg variant="top" src={staff.photoURL}/>
      )}
      <CardBody>
        <CardTitle className="my-2">
          <strong className="font-xx-large">Private Chat</strong>
        </CardTitle>

        {(staff.languages !== undefined && 0 < staff.languages.length) && (
          <>
            <div>
              <i className="fa-regular fa-star"/> 日程 - {Staff.getDateLabel(date)}
            </div>
            <div>
              <i className="fa-regular fa-star"/> 言語 - {staff.languages.map(x => x.value).join(" ")}
            </div>
          </>
        )}

        <div>
          <i className="fa-regular fa-star"/> 料金 - {publicConfig.chat.price}円(税込)
        </div>
      </CardBody>
      <CardFooter>
        <div className="d-flex flex-wrap gap-2 align-self-center">
          {slots.map(slot => (
            <div key={slot.time}>
              {props.reservations.find(x => x.time === slot.time) === undefined ? (
                <NavLink
                  to={Paths.reserve.getPath(staff.id, date, slot.time)}
                >
                  <button className="btn btn-primary btn-sm px-3 font-weight-bold">
                    {slot.time}
                  </button>
                </NavLink>
              ) : (
                <button className="btn btn-primary btn-sm px-3 font-weight-bold" disabled={true}>
                  {slot.time}
                </button>
              )}
            </div>
          ))}
        </div>
      </CardFooter>
    </Card>
  );
}
