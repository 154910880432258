import React from "react";
import {Spinner} from "../../components/Spinner";
import {useReservation, useStaff, useUser} from "../../access/firestore-access";
import {Paths} from "../../lib/paths";
import {CancelReservationRequest, Reservation} from "../../../functions/src/shared/entity/reservation";
import {useForm} from "react-hook-form";
import {Responses} from "../../../functions/src/shared/response";
import * as Rx from "rxjs";
import {core} from "../../core";
import {User as FirebaseUser} from "@firebase/auth";
import {useAdminAccessContext} from "../../components/guards/AdminGuard";

export const AdminReservationsDetail = () => {

  const context = useAdminAccessContext();
  const targetReservationId = Paths.admin.reservations.reservation.usePath().reservationId;
  const reservation = useReservation(targetReservationId);

  return <>
    <div className="row">
      <div className="col-12 offset-xl-2 col-xl-8 text-center">
        <h1 className="page-title">Reservation</h1>
      </div>

      {reservation === "loading" ? (
        <div className="col-12 offset-xl-2 col-xl-8 text-center pt-5">
          <Spinner magnification={3}/>
        </div>
      ) : reservation === null ? (
        <div className="col-12 offset-xl-2 col-xl-8 text-center pt-5">
          予約が見つかりませんでした。
        </div>
      ) : (
        <div className="col-12 offset-xl-2 col-xl-8 text-center d-flex flex-column gap-3 pt-5">
          <h3>基本情報</h3>
          <AdminReservationTable firebaseUser={context.user.value.firebase} reservation={reservation}/>
        </div>
      )}
    </div>
  </>
}

export const AdminReservationTable = (props: { firebaseUser: FirebaseUser, reservation: Reservation }) => {

  const staff = useStaff(props.reservation.staffId);
  const user = useUser(props.reservation.userId)
  const form = useForm<CancelReservationRequest & { status: string }>({
    mode: "onBlur",
    defaultValues: {
      reservationId: props.reservation.id,
      status: ""
    }
  })

  const onSubmit = (value: CancelReservationRequest) => {
    if (!confirm("本当にこの予約をキャンセルして良いですか？")) {
      form.setError("root", {})
      return;
    }

    return Rx.firstValueFrom(core.api.cancelReservation(props.firebaseUser, value))
      .then(response => {
        if (response.status === 200) {
          return;
        } else if (response.status === 400) {
          form.setError("status", {message: Responses.messages.systemError})
        } else if (400 < response.status) {
          form.setError("status", {message: response.value.message})
        } else {
          console.error(response)
          form.setError("status", {message: Responses.messages.systemError});
        }
      }).catch(e => {
        console.error(e)
        form.setError("status", {message: Responses.messages.systemError});
      });
  }

  if (form.formState.isSubmitSuccessful) {
    window.location.reload();
  }

  return <>
    <table className="table table-striped">
      <tbody>
      <tr>
        <th>ID</th>
        <td scope="row" className="word-break-break-all">
          {props.reservation.id}
        </td>
      </tr>
      <tr>
        <th>Staff</th>
        <td scope="row" className="word-break-break-all">
          {staff === "loading" ? <Spinner magnification={1}/> : staff?.nickname}
        </td>
      </tr>
      <tr>
        <th>User</th>
        <td scope="row" className="word-break-break-all">
          {user === "loading" ? <Spinner magnification={1}/> : user?.nickname}
        </td>
      </tr>
      <tr>
        <th>DateTime</th>
        <td scope="row">
          {props.reservation.date}&nbsp;{props.reservation.time}
        </td>
      </tr>
      <tr>
        <th>Created At</th>
        <td scope="row">
          {new Date(props.reservation.createdAt).toLocaleString()}
        </td>
      </tr>
      <tr>
        <th>Updated At</th>
        <td scope="row">
          {new Date(props.reservation.updatedAt).toLocaleString()}
        </td>
      </tr>
      <tr>
        <th>Status</th>
        <td scope="row">
          {props.reservation.statusLabel}
        </td>
      </tr>
      <tr>
        <th>Payment ID</th>
        <td scope="row">
          {props.reservation.paymentIntentId}
        </td>
      </tr>
      <tr>
        <th>Action</th>
        <td scope="row">
          <form onSubmit={form.handleSubmit(onSubmit)} className="text-center">
            <button
              className="btn btn-warning btn-sm"
              disabled={props.reservation.cancelled || form.formState.isSubmitting}
            >
              {form.formState.isSubmitting ? <Spinner magnification={1}/> : "キャンセル"}
            </button>

            {form.formState.errors.status?.message && (
              <div className="text-danger">{form.formState.errors.status.message}</div>
            )}
          </form>
        </td>
      </tr>
      </tbody>
    </table>
  </>
}
