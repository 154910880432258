import "../firebase"
import * as FS from 'firebase/firestore';
import {getFirestore} from 'firebase/firestore';
import * as Rx from "rxjs";
import {publicConfig} from "../../functions/src/shared/public-config";
import {useEffect, useState} from "react";
import {User} from "../../functions/src/shared/entity/user"
import {Admin} from "../../functions/src/shared/entity/admin";
import {AdminId, ReservationId, StaffId, UserId} from "../../functions/src/shared/entity/identity";
import {nonNullable} from "../../functions/src/shared/lib/util";
import {DocumentSnapshotLike} from "../../functions/src/shared/types/firestore";
import {Loading, LoadingOption} from "../../functions/src/shared/types/loading";
import {Promises} from "../../functions/src/shared/lib/promises";
import {Staff} from "../../functions/src/shared/entity/staff";
import {Reservation} from "../../functions/src/shared/entity/reservation";
import {QueryConstraint} from "@firebase/firestore";

export type CollectionValue<T> = {
  values: Loading<T[]>,
  next: {
    exists: true,
    load: () => void,
  } | {
    exists: false,
  },
  reload: () => void,
}

const firestore = getFirestore();
if (publicConfig.env === "local") {
  FS.connectFirestoreEmulator(firestore, '127.0.0.1', 8080);
}

export class FirestoreAccess {

  static handleAuthorityError<T>(e: Error, value: T): Promise<T> {
    if (e.name === "FirebaseError" && e.message.indexOf("Missing or insufficient permissions.") !== -1) {
      if (publicConfig.env === "local") {
        console.warn(e);
      }
      return Promises.of(value);
    }
    console.error(e, value);
    throw e;
  }

  static handleAuthorityErrorAsNull(e: Error): Promise<null> {
    return FirestoreAccess.handleAuthorityError(e, null);
  }

  static handleAuthorityErrorAsEmpty(e: Error): Promise<[]> {
    return FirestoreAccess.handleAuthorityError(e, []);
  }

  static async getAdmin(id: AdminId): Promise<Admin | null> {
    return Admin.fromDocumentSnapshot(
      await FS.getDoc(FS.doc(firestore, `admins/${id}`)).catch(FirestoreAccess.handleAuthorityErrorAsNull)
    );
  }

  static async getUser(id: UserId): Promise<User | null> {
    return User.fromDocumentSnapshot(
      await FS.getDoc(FS.doc(firestore, `users/${id}`)).catch(FirestoreAccess.handleAuthorityErrorAsNull)
    );
  }
}

export function useAdmins(limit: number): CollectionValue<Admin> {
  return useCollection("/admins", Admin.fromDocumentSnapshot, limit)
}

export function useAdmin(id: AdminId): LoadingOption<Admin> {
  return useDocument("/admins", id, Admin.fromDocumentSnapshot);
}

export function useUsers(limit: number): CollectionValue<User> {
  return useCollection("/users", User.fromDocumentSnapshot, limit)
}

export function useUser(id: UserId): LoadingOption<User> {
  return useDocument("/users", id, User.fromDocumentSnapshot);
}

export function useStaffs(): CollectionValue<Staff> {
  return useAllCollection("/staffs", {field: "createdAt", direction: "desc"}, Staff.fromDocumentSnapshot)
}

export function useStaff(id: StaffId): LoadingOption<Staff> {
  return useDocument("/staffs", id, Staff.fromDocumentSnapshot);
}

export function useReservations(limit: number): CollectionValue<Reservation> {
  return useCollection("/reservations", Reservation.fromDocumentSnapshot, limit, [
    FS.orderBy("date", "desc")
  ])
}

export function useRefundableReservations(limit: number): CollectionValue<Reservation> {
  return useCollection("/reservations", Reservation.fromDocumentSnapshot, limit, [
    FS.orderBy("date", "desc"),
    FS.where("cancelled", "==", true),
    FS.where("status", "==", "success")
  ])
}

export function useReservationsByDate(dates: Date[]): CollectionValue<Reservation> {
  return useCollection("/reservations", Reservation.fromDocumentSnapshot, undefined, [
    FS.where("date", "in", dates.map(Reservation.toDateString))
  ])
}

export function useReservationsByUserId(limit: number, userId: UserId): CollectionValue<Reservation> {
  return useCollection("/reservations", Reservation.fromDocumentSnapshot, limit, [
    FS.where("userId", "==", userId),
    FS.orderBy("date", "desc")
  ])
}

export function useReservation(id: ReservationId): LoadingOption<Reservation> {
  return useDocument("/reservations", id, Reservation.fromDocumentSnapshot);
}

function useDocument<T>(path: string, id: LoadingOption<string>, converter: (doc: DocumentSnapshotLike | null) => T | null): LoadingOption<T> {
  const [document, setDocument] = useState<LoadingOption<DocumentSnapshotLike>>("loading");

  useEffect(() => {
    if (id === "loading") {
      setDocument("loading");
      return;
    }
    if (id === null) {
      setDocument(null);
      return;
    }
    const subscription = Rx.from(
      FS.getDoc(FS.doc(firestore, path + "/" + id))
        .then(doc => setDocument(doc))
        .catch(FirestoreAccess.handleAuthorityErrorAsNull)
    ).subscribe();

    return () => subscription.unsubscribe();
  }, [path, id]);

  return document === "loading" ? "loading" : converter(document);
}

type OrderBy = { field: string, direction: "asc" | "desc" }

function useAllCollection<T>(path: string, orderBy: OrderBy, converter: (doc: DocumentSnapshotLike) => T | null): CollectionValue<T> {
  const [documents, setDocuments] = useState<Loading<DocumentSnapshotLike[]>>("loading");

  useEffect(() => {
    const query = FS.query(
      FS.collection(firestore, path),
      FS.orderBy(orderBy.field, orderBy.direction)
    )

    const subscription = Rx.from(FS.getDocs(query).then(snapshot => {
      setDocuments(snapshot.docs.map((doc) => {
        return ({id: doc.id, data: () => doc.data()})
      }));
    }).catch(FirestoreAccess.handleAuthorityErrorAsEmpty)).subscribe();

    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firestore, path]);

  return {
    values: documents === "loading" ? "loading" : documents.map(converter).filter(nonNullable),
    next: {
      exists: false,
    },
    reload: () => {
    },
  };
}

function useCollection<T>(
  path: string,
  converter: (doc: DocumentSnapshotLike) => T | null,
  limit: number | undefined = undefined,
  queries: QueryConstraint[] = [],
): CollectionValue<T> {
  const [documents, setDocuments] = useState<Loading<DocumentSnapshotLike[]>>("loading");
  const [lastDocument, setLastDocument] = useState<FS.DocumentData | undefined | null>(undefined);
  const [hasNext, setHasNext] = useState<boolean>(false);
  const [loadTime, setLoadTime] = useState<number>(0);

  useEffect(() => {
    let query = FS.query(
      FS.collection(firestore, path),
      ...queries.concat(limit ? [FS.limit(limit)] : [])
    )

    if (lastDocument) {
      query = FS.query(query, FS.startAfter(lastDocument))
    }

    const subscription = Rx.from(FS.getDocs(query).then(snapshot => {
      const hasNextValue = limit ? snapshot.docs.length === limit + 1 : false;
      setHasNext(hasNextValue);
      const docs = hasNextValue ? snapshot.docs.slice(0, snapshot.docs.length - 1) : snapshot.docs;
      const newDocuments = docs.map((doc) => {
        return ({id: doc.id, data: () => doc.data()})
      });

      setDocuments((prevDocuments) => {
        if (prevDocuments === "loading") {
          return [...newDocuments];
        }
        return [...prevDocuments, ...newDocuments]
      });

      if (0 < docs.length) {
        setLastDocument(docs[docs.length - 1]);
      } else {
        setLastDocument(null);
      }
    }).catch(FirestoreAccess.handleAuthorityErrorAsEmpty)).subscribe();

    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firestore, path, limit, loadTime]);

  const load = () => {
    if (documents !== "loading" && lastDocument) {
      setLoadTime(new Date().getTime());
    }
  };

  const reload = () => {
    if (documents !== "loading") {
      setDocuments("loading");
      setLastDocument(undefined);
      setLoadTime(new Date().getTime());
    }
  }

  return {
    values: documents === "loading" ? "loading" : documents.map(converter).filter(nonNullable),
    next: hasNext ? {
      exists: true,
      load,
    } : {
      exists: false,
    },
    reload,
  };
}
