import "../firebase"
import * as Rx from "rxjs"
import * as FA from "firebase/auth";
import {connectAuthEmulator, getAuth, UserInfo} from "firebase/auth";
import {FacebookAuthProvider, GoogleAuthProvider, User as FirebaseUser, User, UserCredential} from "@firebase/auth";
import {useObservable} from "rxjs-hooks";
import {nonNullable} from "../../functions/src/shared/lib/util";
import {FirebaseError} from "@firebase/util";
import {LoadingOption} from "../../functions/src/shared/types/loading";
import {publicConfig} from "../../functions/src/shared/public-config";

export class Providers {
  public readonly google: UserInfo | undefined;
  public readonly password: UserInfo | undefined;

  constructor(providerData: UserInfo[]) {
    this.google = providerData.find(x => x.providerId === "google.com");
    this.password = providerData.find(x => x.providerId === "password");
  }

  find(provider: FirebaseAuthProvider): UserInfo | undefined {
    if (provider === "Google") {
      return this.google;
    }
    return undefined;
  }



  get length(): number {
    return [this.google, this.password].filter(nonNullable).length;
  }

  static isMatch(providerId: string | undefined, provider: FirebaseAuthProvider): boolean {
    if (providerId === undefined) {
      return false;
    }
    if (providerId === "facebook.com" && provider === "Facebook") {
      return true;
    }
    // noinspection RedundantIfStatementJS
    if (providerId === "google.com" && provider === "Google") {
      return true;
    }
    return false;
  }

  static isNotMatch(providerId: string | undefined, provider: FirebaseAuthProvider): boolean {
    return !Providers.isMatch(providerId, provider);
  }

  static credentialFromError(provider: FirebaseAuthProvider, e: FirebaseError) {
    if (provider === "Google") {
      return GoogleAuthProvider.credentialFromError(e);
    } else if (provider === "Facebook") {
      return FacebookAuthProvider.credentialFromError(e);
    }
    return null
  }
}

export type EmailAndPasswordForm = {
  email: string,
  password: string,
}

export type FirebaseAuthProvider = "Facebook" | "Google"

export const FirebaseAuthProviders = {
  facebook: new FacebookAuthProvider(),
  google: new GoogleAuthProvider(),
};
FirebaseAuthProviders.google.setCustomParameters({prompt: "select_account"});

const auth = getAuth();
auth.languageCode = "ja";
if (publicConfig.env === "local") {
  connectAuthEmulator(auth, "http://127.0.0.1:9099");
}

export class FirebaseAuthAccess {

  static getCurrentFirebaseUser(): Rx.Observable<User | null> {
    return Rx.from(new Promise<User | null>((resolve, reject) => {
      if (auth.currentUser !== null) {
        resolve(auth.currentUser);
      } else {
        const unsubscribe = auth.onAuthStateChanged(user => {
          unsubscribe();
          resolve(user);
        }, reject);
      }
    }));
  }

  public async getIdToken(user: User): Promise<string> {
    return FA.getIdToken(user);
  }

  public async getRedirectResult(): Promise<UserCredential | null> {
    return FA.getRedirectResult(auth);
  }

  public async signInWithRedirect(provider: FirebaseAuthProvider): Promise<void> {
    return FA.signInWithRedirect(
      auth,
      provider === "Facebook"
        ? FirebaseAuthProviders.facebook
        : FirebaseAuthProviders.google,
    );
  }

  public async unlink(user: FirebaseUser, provider: FirebaseAuthProvider): Promise<User> {
    return FA.unlink(
      user,
      provider === "Facebook"
        ? FirebaseAuthProviders.facebook.providerId
        : FirebaseAuthProviders.google.providerId,
    );
  }

  public async linkWithRedirect(user: FirebaseUser, provider: FirebaseAuthProvider): Promise<void> {
    return FA.linkWithRedirect(
      user,
      provider === "Facebook"
        ? FirebaseAuthProviders.facebook
        : FirebaseAuthProviders.google,
    );
  }

  public async signInWithEmailAndPassword(form: EmailAndPasswordForm): Promise<UserCredential> {
    return FA.signInWithEmailAndPassword(
      auth,
      form.email,
      form.password,
    );
  }

  public async createUserWithEmailAndPassword(form: EmailAndPasswordForm): Promise<UserCredential> {
    return FA.createUserWithEmailAndPassword(auth, form.email, form.password)
  }

  public async sendEmailVerification(user: FirebaseUser): Promise<void> {
    return FA.sendEmailVerification(user)
  }

  public async sendPasswordResetEmail(email: string): Promise<void> {
    return FA.sendPasswordResetEmail(auth, email, {url: window.location.href})
  }

  public async updateEmail(user: FirebaseUser, email: string): Promise<void> {
    return FA.updateEmail(user, email)
  }

  public async updateDisplayName(user: FirebaseUser, displayName: string): Promise<void> {
    return FA.updateProfile(user, {displayName})
  }

  public async updatePhotoURL(user: FirebaseUser, photoURL: string): Promise<void> {
    return FA.updateProfile(user, {photoURL})
  }

  public async verifyBeforeUpdateEmail(user: FirebaseUser, email: string): Promise<void> {
    return FA.verifyBeforeUpdateEmail(user, email)
  }

  public async updatePassword(user: FirebaseUser, password: string): Promise<void> {
    return FA.updatePassword(user, password)
  }

  public async signOut(): Promise<void> {
    return auth.signOut();
  }
}

export function useCurrentFirebaseUser(): LoadingOption<FirebaseUser> {
  return useObservable<LoadingOption<FirebaseUser>>(() => FirebaseAuthAccess.getCurrentFirebaseUser(), "loading");
}
