import React, {useId, useState} from "react";
import {Paths} from "../lib/paths";
import {useReservationsByDate, useStaffs} from "../access/firestore-access";
import {ReservationId} from "../../functions/src/shared/entity/identity";
import {Staff} from "../../functions/src/shared/entity/staff";
import {Spinner} from "../components/Spinner";
import {TimeRange} from "../components/TimeRange";
import {publicConfig} from "../../functions/src/shared/public-config";
import {useCommonAccessContext} from "../components/guards/CommonAccessGuard";
import {NavLink} from "react-router-dom";
import {useForm} from "react-hook-form";
import {core} from "../core";
import {firstValueFrom} from "rxjs";
import {toMap} from "../../functions/src/shared/lib/util";
import {Responses} from "../../functions/src/shared/response";
import {Reservation, Resumption} from "../../functions/src/shared/entity/reservation";
import {getStripe} from "../access/stripe-access";
import {User as FirebaseUser} from "@firebase/auth";
import {usePromise} from "../util/hook";
import { ScrollToTop } from "../components/ScrollToTop";

export const Reserve = () => {

  const {staffId, date, time} = Paths.reserve.usePath();
  const staffs = useStaffs();
  const parsed = new Date(`${date}T00:00:00.000Z`);

  if (staffs.values === "loading") {
    return (
      <div className="row pt-5">
        <div className="col-12 offset-md-2 col-md-8 text-center">
          <Spinner magnification={3}/>
        </div>
      </div>
    )
  }

  const staff = staffs.values.find(x => x.id === staffId);

  if (staff === undefined) {
    return (
      <div className="row pt-5">
        <div className="col-12 offset-md-2 col-md-8 text-center">
          <>スタッフが存在しません。</>
        </div>
      </div>
    )
  }

  return <>
    <SlotDetail staff={staff} date={parsed} time={time}/>
  </>
}

const SlotDetail = (props: { staff: Staff, date: Date, time: string }) => {

  const context = useCommonAccessContext();
  const reservations = useReservationsByDate([props.date])

  if (reservations.values === "loading") {
    return (
      <div className="row pt-5">
        <div className="col-12 offset-md-2 col-md-8 text-center">
          <Spinner magnification={3}/>
        </div>
      </div>
    )
  }

  const reserved = reservations.values
    .filter(x => x.staffId === props.staff.id)
    .filter(x => !x.cancelled)
    .find(x => x.time === props.time)

  if (reserved !== undefined) {
    return (
      <div className="row pt-5">
        <div className="col-12 offset-md-2 col-md-8 text-center">
          <>既にこの枠は予約されています。</>
        </div>
      </div>
    )
  }

  const slot = props.staff.slots?.find(x =>
    x.day === props.date.getDay()
    && x.time === decodeURIComponent(props.time)
  )

  if (slot === undefined) {
    return (
      <div className="row pt-5">
        <div className="col-12 offset-md-2 col-md-8 text-center">
          <>この予約枠はありません。</>
        </div>
      </div>
    )
  }

  return <>
    <ScrollToTop />
    <div className="row d-flex flex-column gap-5">
      <div className="col-12 offset-md-2 col-md-8 d-flex flex-column mt-5 gap-3">
        <h1 className="reservation-title d-flex gap-2 justify-content-center">
          Private Chat
        </h1>

        <h2 className="reservation-subtitle mb-3 d-flex gap-2 justify-content-center">
          <span>{Staff.getDateLabel(props.date)}</span>
          <span><TimeRange base={slot.time} minutes={publicConfig.chat.minutes}/></span>
        </h2>

        <div className="d-flex justify-content-center">
          Google Meetを使って{publicConfig.chat.minutes}分間のオンラインチャットをお楽しみいただけます☆
        </div>

        <ul style={{lineHeight: 2}}>
          <li>担当チャットホスト&nbsp;{props.staff.nickname}（From {props.staff.country}）</li>
          <li>チャットでご利用できる言語&nbsp;&nbsp;{props.staff.languages?.map(x => x.value).join(" ")}</li>
          <li>お申込み料金&nbsp;&nbsp;{publicConfig.chat.price}円（税込み）</li>
          <li>キャンセル規定&nbsp;&nbsp;前日までは{publicConfig.chat.cancelFee}円、当日は{publicConfig.chat.price}円のキャンセル費用がかかります</li>
        </ul>

        {props.staff.photoURL && (
          <img src={props.staff.photoURL} alt={props.staff.nickname} className="w-100"/>
        )}

        <p style={{whiteSpace: "pre-line"}}>
          {props.staff.introduction}
        </p>

        {context.user.value === "loading"
          ? <Spinner magnification={3}/>
          : context.user.value === null
            ? (
              <div className="d-flex justify-content-center gap-4">
                <NavLink className="btn rounded-pill px-4 py-3 px-sm-5 btn-pink" to={Paths.register.pathWithLocation(window.location)}>Register</NavLink>
                <NavLink className="btn rounded-pill px-4 py-3 px-sm-5 btn-sky" to={Paths.login.pathWithLocation(window.location)}>Sign In</NavLink>
              </div>
            )
            : (
              <>
                <h2 className="box19 mb-3">
                  チャットお申込み
                </h2>

                <div className="text-center">
                  <ReservationButton user={context.user.value.firebase} staff={props.staff} date={props.date} time={slot.time}/>
                </div>
              </>
            )
        }
      </div>
    </div>
  </>
}

type ReservationFormValue = {
  paymentMethodId: string
}

const ReservationButton = (props: { user: FirebaseUser, staff: Staff, date: Date, time: string }) => {

  const [reservationId, setReservationId] = useState<ReservationId>();
  const form = useForm<ReservationFormValue>({mode: "onBlur"});
  const id = useId();

  const paymentMethods = usePromise(() =>
      firstValueFrom(core.api.listPaymentMethods(props.user)),
    [props.user]
  )

  if (paymentMethods === "loading") {
    return <Spinner magnification={3}/>
  }

  if (paymentMethods === "error") {
    return <div className="text-danger">{Responses.messages.systemError}</div>
  }

  if (paymentMethods.value.length === 0) {
    return (
      <>
        <NavLink className="btn btn-primary btn-lg" to={Paths.my.paymentMethods.path}>お支払い方法を登録する</NavLink>
      </>
    )
  }

  if (form.formState.isSubmitSuccessful && reservationId) {
    window.location.href = `https://leafcup.com/thanks/chat.html?id=${reservationId}`
    return;
  }

  const onSubmit = async (value: ReservationFormValue) => {
    if (!confirm(`ご予約と${publicConfig.chat.price}円(税込)のお支払を行います。本当によろしいでしょうか？`)) {
      form.setError("root", {});
      return;
    }
    try {
      const result = await firstValueFrom(core.api.createReservation(
        props.user,
        {
          staffId: props.staff.id,
          date: Reservation.toDateString(props.date),
          time: props.time,
          paymentMethodId: value.paymentMethodId
        }
      ))
      if (result.status == 200) {
        if (await handlePaymentResult(props.user, result.value.reservationId, result.value.resumption)) {
          setReservationId(result.value.reservationId)
        }
        return
      }
      if (result.status === 400) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        for (const [_key, value] of toMap(result.value.errors)) {
          form.setError("root", {message: Responses.messages.convert(value)})
        }
        return
      }
      if ((result.status / 100 | 0) === 4) {
        form.setError("root", {message: result.value.message})
        return
      }
    } catch (error) {
      console.error("Unhandled exception.", error)
    }
    form.setError("root", {message: Responses.messages.systemError})
  }

  async function handlePaymentResult(user: any, id: ReservationId, src: Resumption): Promise<boolean> {
    const stripe = await getStripe()
    for (; ;) {
      switch (src.status) {
        case "failure": {
          form.setError("root", {
            message: Responses.messages.systemError
          })
          return false
        }
        // 3DS
        case "interrupted": {
          const {error} = await stripe.confirmPayment({
            clientSecret: src.clientSecret,
            confirmParams: {
              return_url: "https://www.example.com/"
            },
            redirect: 'if_required'
          })
          if (error) {
            form.setError("root", {
              message: error.message ?? Responses.messages.systemError
            })
            return false
          }
          break
        }
        case "success": {
          setReservationId(id)
          return true
        }
        case "tbd": {
          await new Promise(resolve => {
            setTimeout(resolve, 1000)
          })
          break
        }
      }

      const result = await firstValueFrom(core.api.resumePayment(user, id))
      if (result.status !== 200) {
        form.setError("root", {
          message: Responses.messages.systemError
        })
        return false
      }
      src = result.value
    }
  }

  return <>
    <form onSubmit={form.handleSubmit(onSubmit)} className="reservation-form d-flex flex-column gap-3">
      <div className="row">
        <div className="col-12 col-sm-4 mt-3 d-flex align-items-center gap-2">
          <span className="Form-Item-Label-Required">必須</span>
          <label className="font-weight-bold" htmlFor={id}>
            お支払方法
          </label>
        </div>

        <div className="col-12 col-sm-8 mt-3">
          <select
            id={id}
            className="form-select"
            {...form.register("paymentMethodId", {
              required: Responses.messages.requiredNotSelected,
            })}
          >
            <option value="">選択してください</option>
            {paymentMethods.value.map(e =>
              <option key={e.id} value={e.id}>{e.name}</option>
            )}
          </select>
        </div>

        {form.formState.errors.paymentMethodId?.message && (
          <div className="text-danger">{form.formState.errors.paymentMethodId.message}</div>
        )}
      </div>

      <div className="row">
        <div className="mt-5 text-center">
          <button className="Form-Btn" disabled={form.formState.isSubmitting}>
            {form.formState.isSubmitting ? <Spinner magnification={1}/> : <>ご予約＆お支払</>}
          </button>

          {form.formState.errors.root?.message &&
            <div className="text-danger">{form.formState.errors.root.message}</div>
          }
        </div>
      </div>
    </form>
  </>
}
