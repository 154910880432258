import React from "react";
import {useSignUpAccessContext} from "../components/guards/SignUpGuard";
import {Providers} from "../access/firebase-auth-access";
import {LoginPasswordUpdate} from "../components/LoginPasswordUpdate";

export const AccountPasswordUpdate = () => {

  const context = useSignUpAccessContext();
  const providers = new Providers(context.user.value.firebase.providerData);

  return <>
    <div className="row">
      <div className="col-12 text-center">
        <h1 className="page-title">パスワードの変更</h1>
      </div>
    </div>

    <div className="row mt-5">
      <div className="col-12 offset-sm-2 col-sm-8 col-lg-4 offset-lg-4 pt-3">
        <LoginPasswordUpdate user={context.user.value} previous={providers.password}/>
      </div>
    </div>
  </>
}
