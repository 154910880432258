import React from "react";
import {useSignUpAccessContext} from "../components/guards/SignUpGuard";
import {Providers} from "../access/firebase-auth-access";
import {NavLink} from "react-router-dom";
import {Paths} from "../lib/paths";
import {EmailVerifiedIcon} from "../components/EmailVerifiedIcon";
import {LoginLinkButton} from "../components/LoginLinkButton";
import {EmailVerifyAlert} from "../components/EmailVerifyAlert";
import {UserProfileIcon} from "../components/UserProfileIcon";
import {core} from "../core";

export const My = () => {

  const context = useSignUpAccessContext();
  const providers = new Providers(context.user.value.firebase.providerData);

  const actionLabel = providers.password !== undefined ? "更新" : "設定"

  return <>
    <div className="row">
      <div className="col-12 mt-3">
        <NavLink to={Paths.top.path}>&lt;&lt;予約ページに戻る</NavLink>
      </div>
      <div className="col-12 text-center">
        <h1 className="page-title">マイページ</h1>
      </div>
    </div>

    <div className="row mt-5">
      <div className="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3 d-flex flex-column gap-5 pt-3">
        <div className="d-flex flex-column gap-3 align-items-center">

          <UserProfileIcon
            firebaseUser={context.user.value.firebase}
            magnification={5}
            editable={{value: true, dialog: context.dialog}}
          />

          <div className="text-center">
            <strong>
              {context.user.value.service.nickname}
              <NavLink to={Paths.my.profile.path}>
                <i className="fas fa-pen ms-2 font-small"/>
              </NavLink>
            </strong>

            <div className="font-small">
              ID: {context.user.value.firebase.uid}
            </div>
          </div>
        </div>

        <div className="d-flex flex-column gap-3 align-items-center">
          <div className="card w-100">
            <h5 className="card-header d-flex">
              <span className="flex-grow-1">メールアドレス</span>
            </h5>

            <div className="card-body">
              <div className="card-text">
                <EmailVerifiedIcon emailVerified={context.user.value.firebase.emailVerified}/>
                {context.user.value.firebase.email}
              </div>

              <EmailVerifyAlert firebaseUser={context.user.value.firebase}/>
            </div>
          </div>
        </div>

        <div className="d-flex flex-column gap-3 align-items-center">
          <NavLink to={Paths.my.reservations.path}>ご予約一覧</NavLink>
        </div>

        <div className="d-flex flex-column gap-3 align-items-center">
          <NavLink to={Paths.my.paymentMethods.path}>お支払い方法一覧</NavLink>
        </div>

        <div className="d-flex flex-column gap-3 align-items-center">
          <div className="card w-100">
            <h5 className="card-header d-flex">
              <span className="flex-grow-1">Googleログイン</span>
            </h5>

            <div className="card-body">
              <div className="card-text">
                {providers.google !== undefined ? providers.google.email : "未連携"}
              </div>

              <div className="card-text text-end">
                <LoginLinkButton firebaseUser={context.user.value.firebase} provider={"Google"} providers={providers}/>
              </div>
            </div>
          </div>

          <div className="card w-100">
            <h5 className="card-header d-flex">
              <span className="flex-grow-1">パスワードログイン</span>
            </h5>

            <div className="card-body">
              <div className="card-text">
                {providers.password === undefined ? "未設定" : providers.password.uid}
              </div>

              <div className="card-text text-end">
                {providers.password !== undefined && (
                  <NavLink to={Paths.login.pathWithFrom(Paths.account.update.email.path)} className="ms-2">
                    メールアドレスの変更
                  </NavLink>
                )}
                <NavLink to={Paths.login.pathWithFrom(Paths.account.update.password.path)} className="ms-2">
                  パスワードの{actionLabel}
                </NavLink>
              </div>
            </div>
            <div className="card-footer">
              <span className="text-muted font-small">
                ※ メールアドレス/パスワードを変更する場合、再ログインが必要になります。
              </span>
            </div>
          </div>
        </div>

        <div className="d-flex flex-column gap-3 align-items-center pt-3">
          <span className="link-like" onClick={() => {
            const x = core.auth.signOut();
            window.location.reload();
            return x;
          }}>
            ログアウト
          </span>
          <NavLink to={Paths.account.unregister.path} className="text-danger font-small">アカウントの削除</NavLink>
        </div>
      </div>
    </div>
  </>
}
