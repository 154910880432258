import {AdminId} from "./identity";
import {DocumentSnapshotLike} from "../types/firestore";
import {BadRequestErrorResponse, BadRequestField, ConflictErrorResponse, NotFoundErrorResponse, Responses, SuccessOKResponse} from "../response";

export class Admin {
  constructor(
    readonly id: AdminId,
    readonly createdAt: number,
    readonly updatedAt: number,
    readonly tools: string[],
  ) {
  }

  static readonly ID_MIN_LENGTH = 6;

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  // noinspection JSUnusedGlobalSymbols
  toDocumentData() {
    return {
      // id は document path に入るので入れない
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      tools: this.tools,
    };
  }

  static fromDocumentSnapshot(doc: DocumentSnapshotLike | null): Admin | null {
    if (!doc) {
      return null;
    }
    const data = doc.data();
    if (data === undefined) {
      return null;
    }

    return new Admin(
      AdminId(doc.id),
      data["createdAt"],
      data["updatedAt"],
      data["tools"],
    );
  }

  static validate(request: UpdateAdminRequest): true | BadRequestField<"operation" | "adminId"> {
    if (request.operation === "REMOVE") {
      return true;
    }

    const errors: BadRequestField<"operation" | "adminId"> = [];

    if (request.operation) {
      const operation = request.operation
      // noinspection SuspiciousTypeOfGuard
      if (typeof operation !== "string") {
        errors.push(["operation", "TYPE_NOT_MATCH"])
      } else {
        if (operation !== "ADD" && operation !== "REMOVE") {
          errors.push(["operation", "UNKNOWN"])
        }
      }
    } else {
      errors.push(["operation", "MISSING"])
    }

    if (request.adminId) {
      const adminId = request.adminId
      // noinspection SuspiciousTypeOfGuard
      if (typeof adminId !== "string") {
        errors.push(["adminId", "TYPE_NOT_MATCH"])
      } else {
        if (adminId.length <= Admin.ID_MIN_LENGTH) {
          errors.push(["adminId", "TOO_SHORT"])
        }
        if (!Responses.messages.ascii(adminId)) {
          errors.push(["adminId", "INVALID_CHARACTER"])
        }
      }
    } else {
      errors.push(["adminId", "MISSING"])
    }

    return errors.length === 0 ? true : errors;
  }
}

export type UpdateAdminRequest = {
  operation: "ADD" | "REMOVE",
  adminId: AdminId,
}

export type UpdateAdminResponse = SuccessOKResponse<null>
  | BadRequestErrorResponse<"operation" | "adminId">
  | ConflictErrorResponse
  | NotFoundErrorResponse
