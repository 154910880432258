import React, {useState} from "react";
import * as Rx from "rxjs";
import {Query} from "../../functions/src/shared/lib/query";
import {UserId} from "../../functions/src/shared/entity/identity";
import {Spinner} from "../components/Spinner";
import {core} from "../core";
import {EmailAndPasswordForm, FirebaseAuthProvider} from "../access/firebase-auth-access";
import {Navigate, NavLink} from "react-router-dom";
import {Paths} from "../lib/paths";
import {useCommonAccessContext} from "../components/guards/CommonAccessGuard";
import {useForm} from "react-hook-form";
import {FirestoreAccess} from "../access/firestore-access";
import {Responses} from "../../functions/src/shared/response";
import {LoginPasswordReset} from "../components/LoginPasswordReset";

export const AccountLogin = () => {
  const context = useCommonAccessContext();
  const query = new Query(window.location.search.slice(1));
  const [status, setStatus] = useState<"LOGIN_FORM" | "SUCCESS" | "PROCESSING" | "NOT_REGISTERED" | "REGISTER_PENDING" | "BANNED">("PROCESSING");
  const form = useForm<EmailAndPasswordForm>({mode: "onBlur"})

  const onClickSignInButton = (provider: FirebaseAuthProvider) => {
    setStatus("PROCESSING");
    return core.auth.signInWithRedirect(provider);
  };

  React.useEffect(() => {
    const userValue = context.user.value;
    if (userValue === "loading") {
      return;
    }
    const subscription = Rx.from(core.auth.getRedirectResult().then(async cred => {
      if (cred === null) {
        setStatus((userValue === null || userValue.service !== null) ? "LOGIN_FORM" : "REGISTER_PENDING");
        return;
      }
      const user = await FirestoreAccess.getUser(UserId(cred.user.uid))
      if (user === null) {
        setStatus("NOT_REGISTERED")
        return;
      }
      context.user.set({service: user, firebase: cred.user});
      setStatus("SUCCESS");
    }, error => {
      if (error.code === "auth/user-disabled") {
        setStatus("BANNED");
        return;
      } else {
        console.error(error);
        alert(Responses.messages.systemError);
      }
    })).subscribe();

    return () => subscription.unsubscribe();
  }, [context.user]);

  React.useEffect(() => {
    // リダイレクトで戻ってくると Firebase Auth 側の登録が完了してしまい、
    // 登録作業を離脱したユーザーと見分けがつかないため、ログアウト処理を実行する
    if (status === "NOT_REGISTERED") {
      const subscription = Rx.from(core.auth.signOut()
        .then(() => context.user.set(null))
        .catch(e => {
          console.error(e);
          form.setError("root", {message: Responses.messages.systemError});
        })).subscribe();
      return () => subscription.unsubscribe();
    } else {
      return () => {
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  const onSubmitEmailAndPasswordForm = (value: EmailAndPasswordForm) => {
    return core.auth.signInWithEmailAndPassword(value).then(cred => {
      return FirestoreAccess.getUser(UserId(cred.user.uid)).then(user => {
        context.user.set({service: user, firebase: cred.user});
        setStatus(user !== null ? "SUCCESS" : "REGISTER_PENDING");
        return;
      })
    }).catch(e => {
      if (e.code === "auth/invalid-credential") {
        form.setError("password", {message: "このメールアドレスではアカウントが登録されていないか、メールアドレスもしくはパスワードが誤っています。"});
        return;
      } else if (e.code === "auth/invalid-email") {
        form.setError("email", {message: "メールアドレスの形式が正しくありません。"});
        return;
      } else if (e.code === "auth/user-disabled") {
        setStatus("BANNED");
        return;
      } else {
        console.error(e.code, e);
        form.setError("root", {message: Responses.messages.systemError});
        return;
      }
    });
  }

  if (status === "SUCCESS") {
    return <Navigate to={Paths.from(query.getAsOpt("from"), Paths.my.path)}/>
  }

  return <>
    <div className="row">
      <div className="col-12 mt-3">
        <NavLink to={Paths.top.path}>&lt;&lt;予約ページに戻る</NavLink>
      </div>
      <div className="col-12 offset-sm-2 col-sm-8 col-lg-6 offset-lg-3 text-center pt-3">
        {(status === "PROCESSING") ? (
          <>
            <Spinner magnification={3}/>
          </>
        ) : (
          <>
            <div className="d-flex flex-column gap-3">
              <div>
                <h1 className="page-title">ログイン</h1>
              </div>

              <div className="d-flex flex-column gap-3">
                <button className="w-100 btn btn-primary" onClick={() => onClickSignInButton("Google")}>
                  <i className="me-2 fa-fw fab fa-google"></i>
                  Googleでログイン
                </button>

                <hr/>

                <form onSubmit={form.handleSubmit(onSubmitEmailAndPasswordForm)} className="d-flex flex-column gap-1">
                  <input
                    className="form-control"
                    placeholder="example@example.com"
                    type="text"
                    disabled={form.formState.isSubmitting}
                    {...form.register("email", {required: Responses.messages.required})}
                  />
                  {form.formState.errors.email?.message &&
                    <span className="text-danger">{form.formState.errors.email.message}</span>
                  }

                  <input
                    className="form-control"
                    placeholder="password"
                    type="password"
                    disabled={form.formState.isSubmitting}
                    {...form.register("password", {required: Responses.messages.required})}
                  />
                  {form.formState.errors.password?.message &&
                    <span className="text-danger">{form.formState.errors.password.message}</span>
                  }

                  <button className="w-100 btn btn-outline-dark" disabled={form.formState.isSubmitting}>
                    {form.formState.isSubmitting
                      ? <Spinner magnification={1}/>
                      : (
                        <>
                          <i className="me-2 fa-fw fas fa-envelope"></i>
                          メールアドレスでログイン
                        </>
                      )
                    }
                  </button>
                </form>
              </div>

              {status === "BANNED" && (
                <span className="text-danger">
                  このアカウントは停止されました。<br/>
                  異議申し立てがある場合は、お問い合わせフォームからお問い合わせ下さい。
                </span>
              )}

              {status === "NOT_REGISTERED" && (
                <span className="text-danger">
                  アカウントが登録されていません。<br/>
                  <NavLink to={Paths.register.path}>会員登録ページから会員登録</NavLink>をお願いします。
                </span>
              )}

              {status === "REGISTER_PENDING" && (
                <span className="text-danger">
                  アカウントの登録処理が途中の状態です。<br/>
                  <NavLink to={Paths.register.path}>会員登録ページから会員登録</NavLink>をお願いします。
                </span>
              )}

              <div>
                <NavLink to={Paths.register.path}>会員登録はこちら</NavLink>
              </div>

              <div>
                <LoginPasswordReset/>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  </>
}
