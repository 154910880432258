import {Stripe, loadStripe} from '@stripe/stripe-js'
import {publicConfig} from "../../functions/src/shared/public-config"

export const stripePromise = loadStripe(publicConfig.stripe.publicKey)

export async function getStripe(): Promise<Stripe> {
  const stripe = await stripePromise
  if (stripe === null) {
    throw new Error("Something went wrong.")
  }
  return stripe
}
