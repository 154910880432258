import React from "react";
import {Paths} from "../lib/paths";
import {useReservation} from "../access/firestore-access";
import {Spinner} from "../components/Spinner";
import {NavLink} from "react-router-dom";

export const Reserved = () => {

  const {reservationId} = Paths.reserved.usePath();
  const reservation = useReservation(reservationId);

  if (reservation === null) {
    return (
      <div className="row">
        <div className="col-12 offset-xl-2 col-xl-8 text-center">
          <>予約が見つかりませんでした。</>
        </div>
      </div>
    )
  }

  if (reservation === "loading") {
    return (
      <div className="row">
        <div className="col-12 offset-xl-2 col-xl-8 text-center">
          <Spinner magnification={3}/>
        </div>
      </div>
    )
  }

  return <>
    <div className="row mb-5">
      <div className="col-12 offset-xl-2 col-xl-8 text-center">
        <h1 className="page-title">
          {reservation.statusLabel}
        </h1>
      </div>
    </div>

    <div className="row">
      <div className="col-12 offset-xl-2 col-xl-8 text-center d-flex flex-column gap-2">
        <div>
          予約ID: {reservation.id}
        </div>
        <div>
          日時: {reservation.date} {reservation.time}
        </div>
        <div>
          <div>
            <NavLink to={Paths.top.path}>トップページに戻る</NavLink>
          </div>
          <div>
            <NavLink to={Paths.my.path}>マイページに戻る</NavLink>
          </div>
        </div>
      </div>
    </div>
  </>
}
